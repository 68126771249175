export const initialStateContent = {
  content: [],
  totalPages: 0,
  totalElements: 0,
  last: false,
  numberOfElements: 0,
  size: 10,
  number: 0,
  first: true,
  empty: false,
};

export const initialStateRoute = {
  id: 0,
  zone: {
    id: 0,
    name: "",
  },
  district: {
    id: 0,
    name: "",
    code: 0,
    area: 0,
    canton: {
      id: 0,
      name: "",
      code: 0,
      province: {
        id: 0,
        name: "",
      },
    },
  },
  visited: false,
  day: "",
  comments: "",
}