import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";

export const getRequest = async (page: number, filter: string,) => {
  try {
    const response = await privateFetch.get(URL.REQUEST_INVENTORY(page, UrlBase.registration_x_page, filter));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createRequest = async (data: any) => {
  let responseData = {};

  const requestBody = {
    user: data.user,
    product: data.product,
    number: data.serial,
    status: data.status,
    comments: data.comments
  };
  await privateFetch

    .post(URL.REQUEST_INVENTORY_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_save_error);
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    user: data.user,
    product: data.product,
    number: data.serial,
    status: data.status,
    comments: data.comments

  };
  await privateFetch
    .post(URL.REQUEST_INVENTORY_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_update_error);
    });
  return responseData;
};


export const deleteRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id

  };

  await privateFetch
    .delete(URL.REQUEST_INVENTORY_DELETE, { data: requestBody })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      //throw new Error(properties.com_parval_label_role_delete_error);
      console.log(error)
    });
  return responseData;
};
