import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  styled,
  TextField,
  Autocomplete,
  Grid,
  FormHelperText,
} from "@mui/material";
import { orderStatusAllType } from "../../pages/tabsManagement/orderTypes";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { stringRegExp } from "../../types/Utils";

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  allOrderStatus: orderStatusAllType[] | null;
  handleAddOrUpdateStatusOrder: any;
}

interface changeStatusType {
  clientOrderId: number;
  packageStatusId: {
    id: number;
    description: string;
  };
  description?: string;
  impediment?: boolean;
}

const ChangeStatusModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  allOrderStatus,
  handleAddOrUpdateStatusOrder,
}: DialogProps) => {
  const validation = Yup.object().shape({
    clientOrderId: Yup.number().required("Campo es requerido"),
    packageStatusId: Yup.object()
      .shape({
        id: Yup.number().required("Campo es requerido"),
        description: Yup.string().required("Campo es requerido"),
      })
      .typeError("Campo es requerido")
      .required("Campo es requerido"),
    description: Yup.string().when(
      "packageStatusId",
      (packageStatusId, schema): any => {
        if (packageStatusId[0].id === 5) {
          return schema
            .required("Campo es requerido")
            .test(
              "len",
              "La nota de la orden es requerida y debe tener una longitud de entre 20 a 255 caracteres",
              (val) => val.length >= 20 && val.length <= 255
            );
        }
      }
    ),
    impediment: Yup.boolean(),
  });

  const defaultValues = {
    clientOrderId: data?.id ?? 0,
    packageStatusId: {
      id: 0,
      description: "",
    },
    description: "",
    impediment: false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<changeStatusType>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });
  const watch_packageStatusId = watch("packageStatusId");
  const [selectedDriver, setSelectedDriver] = useState<number | null>(null);
  console.log("watch_packageStatusId: ", watch_packageStatusId);

  return (
    <>
      <DialogTitle>Cambiar Estado</DialogTitle>
      <br />
      <DialogContent>
        <Grid container spacing={3} maxWidth="xl" sx={{ pt: 0, pb: 8 }}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="packageStatusId"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(e, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={
                    allOrderStatus !== null
                      ? allOrderStatus
                      : [{ id: 0, description: "" }]
                  }
                  getOptionLabel={(option) => option.description || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  //@ts-ignore
                  value={value}
                  sx={{ pb: 0 }}
                  renderInput={(params: any) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Seleccionar estado"
                        variant="standard"
                        error={!!errors.packageStatusId}
                        helperText={
                          errors.packageStatusId &&
                          errors.packageStatusId.message
                        }
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          {watch_packageStatusId?.id === 5 && (
            <Grid item xs={12}>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nota"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("description")}
                    error={errors.description && Boolean(errors.description)}
                    helperText={
                      errors.description && errors.description.message
                    }
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {"Aceptar"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {"Cancelar"}
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default ChangeStatusModal;
