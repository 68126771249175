import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  useTheme,
  TextField,
  Button,
  IconButton,
  styled,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { SVRecoveryPassRequest } from "../../services/Security";
import { rememberPasword } from "../../types/Login";

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: "none",
  fontWeight: 700,
}));

const RememberPassword = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    authInfo,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    successMsg,
    setSuccessMsg,
    errorMsg,
    loginProvider,
    resetErrorMsg,
    resetSuccessMsg,
  } = useApp();

  const validation = Yup.object().shape({
    username: Yup.string().required("Email es requerido"),
    //.email("Email es invalido"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<any>({
    resolver: yupResolver(validation),
  });

  const handleRememberPass = async (email: string) => {
    try {
      setLoading(true);
      const response: any = await SVRecoveryPassRequest(email);
      if (response) {
        setLoading(false);
        setSuccessMsg(response.info);
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const onSubmit = async (data: any) => {
    console.log(data);
    await handleRememberPass(data);
    //navigate("/dashboard/home");
  };

  return (
    <>
      <form>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              name={"username"}
              control={control}
              render={() => (
                <TextField
                  fullWidth
                  size="small"
                  type="email"
                  placeholder="Usuario"
                  autoComplete="username"
                  {...register("username")}
                  error={errors.username && Boolean(errors.username)}
                  sx={{
                    "& .MuiInput-root:before": {
                      borderBottomColor: "#8bc8bc!important",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PermContactCalendarIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit(onSubmit)}
              sx={{
                mt: 3,
              }}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </form>
      <Typography align="center" sx={{ mt: 6 }} variant="body1">
        Regresar a <LinkTo to="/">inicio de sesión</LinkTo>
      </Typography>
    </>
  );
};

export default RememberPassword;
