import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { formSchemaOptionsName } from "../../types/Utils";
import { properties } from "../../utils/Properties_es";
import { phoneRegExp } from "../../utils/Validations";
import FancyDialogActions from "../../components/utils/FancyDialogActions";
import { useEffect, useState } from "react";
import { getRequestCanton, getRequestDistrict, getRequestProvince, getRequestZone } from "../../services/location/LocationService";
import { getRequest } from "../../services/routes/RoutesService";

interface Location {
  id: number;
  name: string;
}

interface AddOrUpdateRoutesTypes {
  id?: number;
  zone: Location;
  province: Location;
  canton: Location;
  district: Location;
  visited: string;
  day?: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateRoutes = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  //Validación de datos
  const validation = Yup.object().shape({
    id: Yup.number(),
    zone: Yup.object().shape({
      id: Yup.number().required("Campo es requerido"),
      name: Yup.string().required("Campo es requerido"),
    }),
    province: Yup.object().shape({
      id: Yup.number().required("Campo es requerido"),
      name: Yup.string().required("Campo es requerido"),
    }),
    canton: Yup.object().shape({
      id: Yup.number().required("Campo es requerido"),
      name: Yup.string().required("Campo es requerido"),
    }),
    district: Yup.object().shape({
      id: Yup.number().required("Campo es requerido"),
      name: Yup.string().required("Campo es requerido"),
    }),
    visited: Yup.string().oneOf(["visitado", "no visitado"]).required("Campo es requerido"),
  });

  const defaultValues = {
    id: data?.id ?? 0,
    zone: data?.zone ?? { id: 0, name: "" },
    province: data?.district?.canton?.province ?? { id: 0, name: "" },
    canton: data?.district?.canton ?? { id: 0, name: "" },
    district: data?.district ?? { id: 0, name: "" },
    visited: data?.visited ? "visitado" : "no visitado",
    day: data?.day ?? "",
  };

  console.log(data)

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<AddOrUpdateRoutesTypes>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const [provinceData, setProvinceData] = useState<any>(null);
  const [selectedProvince, setSelectedProvince] = useState<any>(null);
  const [cantonData, setCantonData] = useState<any>(null);
  const [selectedCanton, setSelectedCanton] = useState<any>(null);
  const [districtData, setDistrictData] = useState<any>(null);
  const [zoneData, setZoneData] = useState<any>(null);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps: Partial<any> = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
        overflowX: 'auto',
      },
    },
  };

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData();
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const dataInit = async () => {
      if (selectedProvince) {
        let data = await getRequestCanton(selectedProvince);

        if (data) {
          setCantonData(data);
        }
      }
    };
    dataInit();

    // eslint-disable-next-line
  }, [selectedProvince]);

  useEffect(() => {
    const dataInit = async () => {
      if (selectedCanton) {
        let data = await getRequestDistrict(selectedCanton);

        if (data) {
          setDistrictData(data);
        }
      }
    };
    dataInit();

    // eslint-disable-next-line
  }, [selectedCanton]);

  const handleFetchData = async () => {
    // setLoading && setLoading(true);
    try {
      let province = await getRequestProvince();
      if (province) {
        setProvinceData(province);
        let zones = await getRequestZone();
        const mappedZones = zones.map((item: any) => ({
          id: item.zone ? item.zone.id : item.id,
          name: item.zone ? item.zone.name : item.name
        }));
        setZoneData(zones);
      }

      if (data?.district?.canton?.province) {
        const cantons = await getRequestCanton(data.district.canton.province.id);
        setCantonData(cantons);
      }

      if (data?.district?.canton) {
        const districts = await getRequestDistrict(data.district.canton.id);
        setDistrictData(districts);
      }

      //setLoading && setLoading(false);
    } catch (error: any) {
      //setLoading && setLoading(false);
      //setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleProvinceChange = (event: any) => {
    const selectedProvinceName = event.target.value;
    const selectedProvince = provinceData.find((province: any) => province.name === selectedProvinceName).id;
    setSelectedProvince(selectedProvince ? selectedProvince : "");
    setCantonData([])
    setDistrictData([])
  };

  const handleCantonChange = (event: any) => {
    const selectedCantonName = event.target.value;
    const selectedCanton = cantonData.find((canton: any) => canton.name === selectedCantonName).id;
    setSelectedCanton(selectedCanton ? selectedCanton : "");
    setDistrictData([])
  };

  console.log(data)
  console.log(zoneData)

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar ruta" : "Actualizar ruta"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name="zone"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label="Zona"
                    variant="standard"
                    {...field}
                    value={field.value.id || ""}
                    onChange={(e) => {
                      const selectedZone = zoneData.find((zone: any) => zone.id === parseInt(e.target.value));
                      field.onChange(selectedZone);
                    }}
                    error={errors.zone && Boolean(errors.zone)}
                    helperText={errors.zone && errors.zone?.name?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={modalType === "finish"}
                    SelectProps={{
                      MenuProps: MenuProps
                    }}
                  >
                    {zoneData && zoneData.map((zone: any) => (
                      <MenuItem key={zone.id} value={zone.id}>
                        {zone.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={`province.name`}
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={"Provincia"}
                    variant="standard"
                    {...field}
                    error={errors.province && Boolean(errors.province)}
                    helperText={errors.province && errors.province?.name?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={modalType === "finish"}
                    onChange={(e) => {
                      handleProvinceChange(e);
                      field.onChange(e);
                    }}
                    SelectProps={{
                      MenuProps: MenuProps
                    }}
                  >
                    {provinceData && provinceData.map((province: any) => (
                      <MenuItem key={province.id} value={province.name}>
                        {province.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={`canton.name`}
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={"Cantón"}
                    variant="standard"
                    {...field}
                    error={errors.canton && Boolean(errors.canton)}
                    helperText={errors.canton && errors.canton?.name?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={modalType === "finish"}
                    onChange={(e) => {
                      handleCantonChange(e);
                      field.onChange(e);
                    }}
                    SelectProps={{
                      MenuProps: MenuProps
                    }}
                  >
                    {cantonData && cantonData.map((canton: any) => (
                      <MenuItem key={canton.id} value={canton.name}>
                        {canton.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="district"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label="Distrito"
                    variant="standard"
                    {...field}
                    value={field.value.id || ""}
                    onChange={(e) => {
                      const selectedDistrict = districtData.find((district: any) => district.id === parseInt(e.target.value));
                      field.onChange(selectedDistrict);
                    }}
                    error={errors.district && Boolean(errors.district)}
                    helperText={errors.district && errors.district?.name?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={modalType === "finish"}
                    SelectProps={{
                      MenuProps: MenuProps
                    }}
                  >
                    {districtData && districtData.map((district: any) => (
                      <MenuItem key={district.id} value={district.id}>
                        {district.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"day"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Días de visita"
                    type="text"
                    value={value}
                    variant="standard"
                    {...register("day")}
                    error={errors.day && Boolean(errors.day)}
                    helperText={errors.day && errors.day.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="visited"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label="Visitado"
                    variant="standard"
                    {...field}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    error={errors.visited && Boolean(errors.visited)}
                    helperText={errors.visited && errors.visited?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={modalType === "finish"}
                    SelectProps={{
                      MenuProps: MenuProps
                    }}
                  >
                    <MenuItem value="visitado">Visitado</MenuItem>
                    <MenuItem value="no visitado">No visitado</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdateRoutes;
