import { useState, useEffect, Fragment } from "react";
import { AccordionSlots } from "@mui/material/Accordion";
import {
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import {
  KeyIcon,
  TrashIcon,
  PlusIcon,
  PenIcon,
} from "../components/svgicons/SvgIcons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Fade from "@mui/material/Fade";
import FancyPaper from "../components/FancyPaper";
import { useApp } from "../hooks/useApp";
import {
  getRequestUser2,
  findByIdRequest,
  createDriver,
  updateDriver,
  deleteRequest,
  getRequestDriver,
} from "../services/user/User";
import CollapseDrivers from "../components/utils/CollapseDrivers";
import ResourceAccess from "../components/security/ResourceAccess";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import AddOrUpdateDriver from "../components/utils/AddOrUpdateDriver";
import { properties } from "../utils/Properties_es";
import { createUser, updateUser } from "../services/user/User";
import { getRequestBankList } from "../services/banks";
import {
  getRequestCarriageBrand,
  getRequestColor,
  getRequestMeanOfTransport,
} from "../services/drivers/DriversServices";
import { getRequestCompany } from "../services/company/CompanyService";
import DeleteModal from "../components/utils/DeleteModal";
import { useForm } from "react-hook-form";
import FancyDialogActions from "../components/utils/FancyDialogActions";

const initForm = {
  id: 0,
  username: "",
  firstName: "",
  lastName: "",
  password: "",
  phone: null,
  status: "ACTIVE",
  roleID: null,
  activationCode: "",
  bank: "",
  account: "",
  agreement: "",
  numberLicense: "",
  carriage: {
    plate: "",
    typeOfTransport: "",
    brand: "",
    year: new Date(),
    color: "",
  },
};

const ManagementDrivers = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const [preFilter, setPreFilter] = useState<string>("");
  const [page, setPage] = useState(0);
  const [drivers, setDrivers] = useState<any>(null);
  const [banksList, setBanksList] = useState<any>(null);
  const [colorList, setColorList] = useState<any>(null);
  const [brandList, setBrandList] = useState<any>(null);
  const [transportList, setTransportList] = useState<any>(null);
  const [companyData, setCompanyData] = useState<any>(null);
  const [formData, setFormData] = useState<any>(initForm);
  const { handleSubmit } = useForm();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
      await handleFetchBanksData();
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  const handleFetchData = async (
    currentPage: number,
    filter: string,
    applyRole?: any
  ) => {
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(currentPage);
      let data = await getRequestDriver(currentPage, filter);
      if (data) {
        setDrivers(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleFetchBanksData = async () => {
    try {
      let data = await getRequestBankList();
      if (data) {
        setBanksList(data);
      }

      let dataColor = await getRequestColor();
      if (dataColor) {
        setColorList(dataColor);
      }

      let dataBrand = await getRequestCarriageBrand();
      if (dataBrand) {
        setBrandList(dataBrand);
      }

      let dataTransport = await getRequestMeanOfTransport();
      if (dataTransport) {
        setTransportList(dataTransport);
      }

      let dataCompany = await getRequestCompany(0, "");
      if (dataCompany) {
        setCompanyData(dataCompany);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleFetchByID = async (id: number) => {
    setLoading && setLoading(true);
    try {
      let userData = await findByIdRequest(id);
      if (userData) {
        //@ts-ignore
        setFormData(userData.content);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update" || modalAction === "passchange") {
      object = drivers.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "delete") {
      object = drivers.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };
  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    console.log(data);
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let createData = await createDriver({
        ...data,
        userCreated: authInfo.username,
        createdAt: new Date().toISOString(),
      });

      if (!createData) {
        setErrorMsg && setErrorMsg("Error en proceso de guardar mensajero");
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_save);
      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    console.log(data);
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let updateData = await updateDriver({
        ...data,
        modifierUser: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_user_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData(page, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let deleteData = await deleteRequest(id);

      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_user_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      setPage(0);
      await handleFetchData(0, preFilter);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    console.log(data);
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FancyPaper pagetitle="Conductores">
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={9} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={"USER:WRITE"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                Agregar mensajero
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <TextField
                placeholder="Filtro de búsqueda"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderRadius: "0.2rem 0 0 0.2rem",
                  },
                  "& fieldset": {
                    borderRightWidth: "0",
                  },
                }}
                value={preFilter}
                onChange={(e: any) => {
                  setPreFilter(e.target.value);
                  if (e.target.value === "") {
                    handleFetchData(0, "");
                  }
                }}
                onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: "0 0.2rem 0.2rem 0!important",
                }}
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableBody>
              {drivers?.content.map((d: any, i: number) => (
                <Fragment key={i}>
                  <CollapseDrivers data={d} handleOpenModal={handleOpenModal} />
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={drivers?.content?.length > 0 ? drivers?.content?.length : 0}
          rowsPerPage={drivers?.size}
          page={page}
          onPageChange={handleChangePage}
          totalElements={drivers?.totalElements}
          totalPages={drivers?.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
          maxWidth="md"
        >
          <AddOrUpdateDriver
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            bankData={banksList}
            colorData={colorList}
            brandData={brandList}
            transportData={transportList}
            companyData={companyData?.content}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                <FancyDialogActions
                  handleAccept={handleSubmit(onSubmit)}
                  cancelModal={handleCancelModal}
                  textAcceptButton={"Eliminar"}
                />
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default ManagementDrivers;
