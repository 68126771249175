import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { getRequest } from "../../services/role/RoleService";
import { activateUser, valideAvailableUser } from "../../services/user/User";
import { formSchemaOptionsName } from "../../types/Utils";
import { properties } from "../../utils/Properties_es";
import { requiredString } from "./Validations";
import { MarkEmailRead } from "@mui/icons-material";
import { PatternFormat } from "react-number-format";

interface ValidationResult {
  exists: boolean;
  // otras propiedades si las hay
}

interface AddOrUpdateUserTypes {
  id?: number;
  email: string;
  identification: string;
  firstName: string;
  lastName: string;
  password?: string;
  phone: string;
  status?: string;
  roleID: {
    id: number;
    roleName: string;
  };
  idCompany?: {
    id?: number;
    name?: string;
  };
  needCode: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType: string;
  roleData?: any;
  companyData?: any;
  handleActiveUser?: any;
  refreshData?: any;
}

const AddOrUpdateUserModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  roleData,
  companyData,
  refreshData,
  //handleActiveUser,
}: DialogProps) => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  //Validación de datos
  const validation = Yup.object().shape({
    email: Yup.string()
      .required("Campo es requerido")
      .email("Email es invalido"),
    identification: Yup.string().required("Campo es requerido"),
    firstName: Yup.string().required("Campo es requerido"),
    lastName: Yup.string().required("Campo es requerido"),
    password: Yup.string().when(modalType, () => {
      return modalType === "create"
        ? Yup.string()
          .required("Contraseña es requerida")
          .min(6, "Contraseña debe tener al menos 8 caracteres")
          .test(
            "password",
            `Formato no es correcto, debe contener al menos 1 mayúsculas, 1 minúsculas, 1 número y 1 carácter especial`,
            (value: any) =>
              value.match(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/
              )?.length > 0
          )
        : Yup.string();
    }),
    phone: Yup.string()
      .required("Campo es requerido")
      .max(20, "Número es muy largo, máximo 20 caracteres"),
    sex: Yup.string(),
    status: Yup.string(),
    roleID: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    idCompany: Yup.lazy(() => {
      const watch_role = watch("roleID");
      if (watch_role && (watch_role.id === 2)) {
        return Yup.object().shape({
          id: Yup.number().required("El campo es requerido"),
          name: Yup.string().required("El campo es requerido"),
        });
      }
      return Yup.mixed().nullable();
    }),
    needCode: Yup.string().oneOf(["Sí", "No"]).required("Campo es requerido"),
  });

  console.log(data)

  const defaultValues = {
    id: data?.id ?? 0,
    email: data?.email ?? "",
    identification: data?.identification ?? "",
    firstName: data?.firstName ?? "",
    lastName: data?.lastName ?? "",
    password: data?.password ?? "",
    phone: data?.phone ?? "",
    status: data?.status ?? "",
    roleID: data?.role
      ? roleData.find((f: any) => f.roleName === data.role.roleName) ?? []
      : [],
    activationCode: data?.activationCode ?? "",
    idCompany: data?.company?.id
      ? {
        id: data.company.id,
        name: companyData.find((f: any) => f.id === data.company.id)?.name ?? "",
      }
      : { id: 0, name: "" },
    needCode: data?.needCode ? "Sí" : "No",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<AddOrUpdateUserTypes>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps: Partial<any> = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
        overflowX: 'auto',
      },
    },
  };

  const watch_role = watch("roleID");

  console.log(watch_role)

  const handleActiveUser = async (data: any) => {
    console.log(data)
    setLoading && setLoading(true);
    try {
      let dataActiveUser = await activateUser(
        data.activationCode,
        data.email
      );
      if (!dataActiveUser) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_user_save_error);
        setLoading && setLoading(false);
        return;
      }
      // await handleFetchByID(formData.id);

      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_save);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
    refreshData(0, "");
    cancelModal();
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {modalType === "create" ? "Agregar usuario" : "Actualizar usuario"}

          {data?.status === 1 && (
            <Button variant="contained" color="primary"  onClick={() => handleActiveUser(data)}>
              Activar usuario
            </Button>
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"email"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Correo electrónico"
                    type="mail"
                    variant="standard"
                    value={value}
                    autoComplete="email3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("email")}
                    error={errors.email && Boolean(errors.email)}
                    helperText={errors.email && errors.email.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"identification"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Identificación"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="identification"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("identification")}
                    error={
                      errors.identification && Boolean(errors.identification)
                    }
                    helperText={
                      errors.identification && errors.identification.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"firstName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="firstName"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("firstName")}
                    error={errors.firstName && Boolean(errors.firstName)}
                    helperText={errors.firstName && errors.firstName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"lastName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Apellido"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="lastName"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("lastName")}
                    error={errors.lastName && Boolean(errors.lastName)}
                    helperText={errors.lastName && errors.lastName.message}
                  />
                )}
              />
            </Grid>
            {modalType === "create" && (
              <Grid item xs={12}>
                <Controller
                  name={"password"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Contraseña"
                      type={showPassword ? "text" : "password"}
                      variant="standard"
                      value={value}
                      autoComplete="off"
                      inputProps={{
                        autoComplete: "no-pass",
                      }}
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("password")}
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {/* <Controller
                name={"phone"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Teléfono"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="phone"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("phone")}
                    error={errors.phone && Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                  />
                )}
              /> */}
              <Controller
                name={"phone"}
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormControl sx={{ width: "100%", marginTop: "20px" }}>
                    <FormLabel
                      sx={{
                        color: theme.palette.secondary.dark,
                        position: "absolute",
                        left: "-10px",
                        top: "-26px",
                        transform: "translate(0, -1.5px) scale(0.75)",
                      }}
                    >
                      Teléfono
                    </FormLabel>
                    <PatternFormat
                      className="input-phone"
                      name={name}
                      onPaste={(e: any) => e.preventDefault()}
                      mask="_"
                      format={"####-####"}
                      value={value}
                      onChange={onChange}
                      placeholder={"####-####"}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="roleID"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={roleData}
                    getOptionLabel={(option: any) => option.roleName || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Perfil"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.roleID}
                          helperText={errors.roleID && errors.roleID.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            {watch_role && (watch_role?.id === 2) && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="idCompany"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={companyData.map((c: any) => {
                        return { id: c.id, name: c.name };
                      })}
                      getOptionLabel={(option: any) => option.name || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      //@ts-ignore
                      value={value}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label={"Compañía"}
                            variant="standard"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={!!errors.idCompany}
                            helperText={
                              errors.idCompany && errors.idCompany?.name?.message
                            }
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                name="needCode"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label="¿Requiere doble factor de autenticación?"
                    variant="standard"
                    {...field}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    error={errors.needCode && Boolean(errors.needCode)}
                    helperText={errors.needCode && errors.needCode?.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={modalType === "finish"}
                    SelectProps={{
                      MenuProps: MenuProps
                    }}
                  >
                    <MenuItem value="Sí">Sí</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default AddOrUpdateUserModal;
