import React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

const CardDashboardWithButton = (props: any) => {
  const { sx, value, iconCard, title, subtitle, colorIcon, buttonText, onButtonClick } = props;

  return (
    <Card
      sx={{
        height: "100%",
        background: "#FAFAFA",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      }}
    >
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.primary" variant="h5">
              {title}
            </Typography>
            <Typography variant="h4">{value}</Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: `${colorIcon}`,
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon sx={{ fontSize: "1.5rem" }}>{iconCard}</SvgIcon>
          </Avatar>
        </Stack>
        <Stack>
          <Typography color="info.dark" variant="caption">
            {subtitle}
          </Typography>
        </Stack>
        <Stack sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={onButtonClick}>
            {buttonText}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

CardDashboardWithButton.propTypes = {
  value: PropTypes.string.isRequired,
  iconCard: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  colorIcon: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default CardDashboardWithButton;
