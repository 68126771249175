import { Button, Card, CardContent, CardHeader, SvgIcon } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Chart } from "react-google-charts";


const PieChartDashboard = (props: any) => {
  const { data, sx, title, titleChart, handleClick, name } = props;

  return (
    <Card
      sx={{
        background: "#FAFAFA",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      }}
    >
      <CardHeader
        action={
          <Button
            color="inherit"
            size="small"
            name={name}
            startIcon={
              <SvgIcon fontSize="small">
                <AutorenewIcon />
              </SvgIcon>
            }
            onClick={handleClick}
          >
            Sync
          </Button>
        }
        title={title}
      />
      <CardContent
        sx={{
          "& rect": {
            fill: "#FAFAFA",
          },
        }}
      >
        <Chart
          chartType="PieChart"
          data={data}
          options={{ title: `${titleChart}` }}
          width={"100%"}
          height={"400px"}
        />
      </CardContent>
    </Card>
  );
};

export default PieChartDashboard;
