import { CheckCircleOutline } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useApp } from "../../hooks/useApp";
import { Content } from "../../services/authority/AuthorityInterfaces";
import { getRequestNoAdded } from "../../services/authority/AuthorityService";
import { CircleCheckIcon } from "../svgicons/SvgIcons";
import FancyTablePagination from "./FancyTablePagination";
import FancyTooltip from "./FancyTooltip";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

// interface AddAuthorityByRoleInter {
//   id?: number;
//   authorityId: string;
//   roleId: string;
// }

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface DialogProps {
  data: any;
  cancelModal: any;
  onSubmit: any;
  refresh: any;
  authorityDataProp: Content[] | null;
}

const AddAuthorityByRole = ({
  cancelModal,
  onSubmit,
  data,
  authorityDataProp,
}: DialogProps) => {
  const theme = useTheme();
  const { setErrorMsg } = useApp();
  const [customLoading, setCustomLoading] = useState<boolean>();
  const [authorityData, setAuthorityData] = useState<Content[] | null>(
    authorityDataProp
  );
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);
  const [authoritySelected, setAuthoritySelected] = useState<number[]>([]);

  useEffect(() => {
    if (authorityDataProp) {
      setAuthorityData(authorityDataProp);
    }
  }, [authorityDataProp]);

  const handleFetchData = async (
    roleId: number,
    currentPage: number,
    size: number,
    filter: string
  ) => {
    setCustomLoading(true);
    try {
      setPage(currentPage);
      let data = await getRequestNoAdded(roleId, currentPage, filter, size);
      if (data) {
        // Si todos los elementos están seleccionados, cerrar el modal
        if (authoritySelected.length === authorityData?.length) {
          cancelModal();
        }
        setAuthorityData(data.content);
      }
      setCustomLoading(false);
    } catch (error: any) {
      setCustomLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(data, 0, 100, preFilter);
    }
  };

  const selectAuthority = async (id: number) => {
    if (authoritySelected.includes(id)) {
      setAuthoritySelected(authoritySelected.filter((i) => i !== id));
    } else {
      setAuthoritySelected([...authoritySelected, id]);
    }
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {"Agregar permiso"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingTop={4}
          paddingBottom={4}
        >
          <Stack direction="row" alignItems="center">
            <TextField
              placeholder={"Filtro de búsqueda"}
              sx={{
                width: "350px",
                "& .MuiInputBase-root": {
                  borderRadius: "0.2rem 0 0 0.2rem",
                },
                "& fieldset": {
                  borderRightWidth: "0",
                },
              }}
              value={preFilter}
              onChange={(e: any) => {
                setPreFilter(e.target.value);
                if (e.target.value === "") {
                  handleFetchData(data, 0, 100, "");
                }
              }}
              onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleApplyFilter}
              sx={{
                borderRadius: "0 0.2rem 0.2rem 0!important",
                padding: "1.5px 16px!important",
              }}
            >
              {"Buscar"}
            </Button>
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: 500 }}>
              {"Seleccionar todo"} / {"Limpiar selección"}
            </Typography>
            <Checkbox
              checked={authoritySelected.length === authorityData?.length}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: "1.5rem",
                },
              }}
              onChange={() => {
                if (authoritySelected.length === authorityData?.length) {
                  setAuthoritySelected([]);
                } else {
                  setAuthoritySelected(
                    authorityData?.map((a: Content) => a.id) || []
                  );
                }
              }}
            />
          </Stack>
        </Stack>

        <Divider sx={{ mb: 4 }} />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{"Identificador"}</TableCell>
                <TableCell>{"Códigos"}</TableCell>
                <TableCell align="center">{"Descripción"}</TableCell>
                <TableCell align="center">{"Acciones"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!customLoading &&
                authorityData &&
                authorityData.length > 0 &&
                authorityData.slice(page * 5, page * 5 + 5).map((r) => (
                  <TableRow key={r.id}>
                    <TableCell component="th" scope="row">
                      {r.id}
                    </TableCell>
                    <TableCell>{r.code}</TableCell>
                    <TableCell>{r.description}</TableCell>
                    <TableCell align="center">
                      <FancyTooltip title="Agregar permiso" placement="top">
                        <IconButton
                          aria-label="create"
                          component="label"
                          color="secondary"
                          sx={{
                            "&:hover": {
                              color: theme.palette.secondary.dark,
                            },
                          }}
                          // onClick={onSubmit}
                          onClick={() => {
                            selectAuthority(r.id);
                          }}
                          data-name="create"
                          data-id={r.id}
                        >
                          {authoritySelected.includes(r.id) ? (
                            <CircleCheckIcon sx={{ pointerEvents: "none" }} />
                          ) : (
                            <CheckCircleOutline />
                          )}
                        </IconButton>
                      </FancyTooltip>
                    </TableCell>
                  </TableRow>
                ))}
              {!authorityDataProp ||
                !authorityDataProp ||
                (authorityDataProp?.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      {"No hay registros"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={authorityData ? authorityData.length : 0}
          rowsPerPage={5}
          page={page}
          onPageChange={handleChangePage}
          totalElements={authorityData?.length}
          totalPages={authorityData ? Math.ceil(authorityData.length / 5) : 0}
        />
      </DialogContent>
      <Actions sx={{ mt: 2, pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={() => {
            onSubmit(authoritySelected);
            cancelModal();
            handleFetchData(data, 0, 100, "");
          }}
        >
          {"Aceptar"}
        </Button>{" "}
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {"Cancelar"}
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddAuthorityByRole;
