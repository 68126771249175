import { CONSTANT } from "./Constants";
export const URL = {
  /** HOME PATH */
  LOGIN_TWOFACTORCODE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/login/twofactor",
  LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/autenticate",
  LOGIN_RECOVERY_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/recoverPasswordAdmin",
  LOGIN_CHANGE_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/changePassword",
  LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/activate",
  SESSIONVALIDATE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",

  // Get Documents

  REQUEST_DOCUMENTS_FORM:
    CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + "/documents/list",

  /** maintenance urls */
  REQUEST_CERTCATEGORY_LIST: (page: string, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/certificate-categories/${page}/${size}`,
  REQUEST_CERTCATEGORY: (id: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/certificate-categories/${id ? id : ""}`,

  REQUEST_CERTIFICATE_LIST: (page: string, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${page}/${size}`,
  REQUEST_CERTIFICATE: (id: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${id ? id : ""}`,

  /** documents urls */
  DOCUMENTS_AREA_UPLOAD:
    CONSTANT.DISPATCHER_DOCUMENTS_PREFIX + `/documents/upload`,
  DOCUMENTS_AREA_GET: (
    companyId: string,
    areaId?: string,
    areaDetailId?: string
  ) =>
    CONSTANT.DISPATCHER_DOCUMENTS_PREFIX +
    `/documents?CompanyId=${companyId}${areaId ? `&AreaId=${areaId}` : ""}${areaDetailId ? `&AreaDetailId=${areaDetailId}` : ""
    }`,
  DOCUMENT_AREA_DELETE: (id: string, user: string) =>
    CONSTANT.DISPATCHER_DOCUMENTS_PREFIX +
    `/documents/delete?id=${id}&user=${user}`,

  DOCUMENTS_AREA_IMG_URL: (id: string) =>
    CONSTANT.DISPATCHER_DOCUMENTS_PREFIX + `/documents/imgUrl/${id}`,

  DOCUMENTS_AREA_VIDEO_URL: (id: string) =>
    CONSTANT.DISPATCHER_DOCUMENTS_PREFIX + `/documents/videoUrl/${id}`,

  /** users urls */
  REQUEST_USER_LIST: (page: string, size: string, company: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/{page}/{size}?page=${page}&size=${size}&company=${company}`,
  REQUEST_USER_LIST2: (page: number, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/{page}/{size}?page=${page}&size=${size}`,
  REQUEST_USER_LIST_USERS_NO_BIKER: (
    page: number,
    size: string,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/allNonBikerAndNonDeletedByFilter/
  ${page}/${size}${filter ? "?filter=" + filter : ""}`,
  REQUEST_DRIVERS_LIST: (page: number, size: string, filter: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/byRoleId/3/${page}/${size}${filter ? "?filter=" + filter : ""}`,

  REQUEST_USER_LIST_FILTER: (page: string, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/${page}/${size}`,
  REQUEST_USER_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/user/createAdmin`,
  REQUEST_USER: (id: any) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/${id ? id : ""}`,
  REQUEST_USER_PASS: CONSTANT.DISPATCHER_USER_PREFIX + `/user/changePassword`,
  REQUEST_ROLE_LIST: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,
  REQUEST_ACTIVATE_USER: (code: string, username: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/activate?code=${code}&username=${username}`,
  REQUEST_VALIDATE_AVAILABLE_USER: (username: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/validateUserName?username=${username}`,

  REQUEST_VALIDATE_AVAILABLE_PASSWORD: (userId: number) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/validateNewPassword?userId=${userId}`,

  /** booking urls */
  REQUEST_BOOKING_LIST: (page: string, size: string, company_id: string) =>
    CONSTANT.DISPATCHER_BOOKING_PREFIX +
    `/area/?page=${page}&size=${size}&company_id=${company_id}`,
  REQUEST_BOOKING_CREATE: (company_id: string) =>
    CONSTANT.DISPATCHER_BOOKING_PREFIX + `/area/?company_id=${company_id}`,
  REQUEST_BOOKING_LIST_FILTER: (page: string, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/${page}/${size}`,
  REQUEST_BOOKING_UPDATE: (id: string, company_id: string) =>
    CONSTANT.DISPATCHER_BOOKING_PREFIX +
    `/area/?company_id=${company_id}&id=${id}`,
  REQUEST_BOOKING_DELETE: (company_id: string, id: string) =>
    CONSTANT.DISPATCHER_BOOKING_PREFIX +
    `/area/?company_id=${company_id}&id=${id}`,

  REQUEST_BOOKING: (id: any) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/area/${id ? id : ""}`,
  REQUEST_BOOKING_PASS:
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/changePassword`,
  REQUEST_BOOKING_BY_WEEK: (company_id: number) =>
    CONSTANT.DISPATCHER_BOOKING_PREFIX +
    `/booking/getBookingByWeek/?company_id=${company_id}`,
  REQUEST_CREATE_BOOKING: (company_id: string) =>
    CONSTANT.DISPATCHER_BOOKING_PREFIX + `/booking/?company_id=${company_id}`,
  REQUEST_BOOKING_APPROVAL: (company_id: string) =>
    CONSTANT.DISPATCHER_BOOKING_PREFIX +
    `/booking/bookingApproval/?company_id=${company_id}`,
  REQUEST_BOOKING_FIND_BY_STATUS: (
    company_id: string,
    page: number,
    size: number,
    status: number,
    area?: any,
    filter?: string
  ) =>
    CONSTANT.DISPATCHER_BOOKING_PREFIX +
    `/booking/by-company?companyId=${company_id}&status=${status}&page=${page}&size=${size}${area ? "&area=" + area : ""
    }${filter ? "&filter=" + filter : ""}`,

  /** report booking payment */
  REQUEST_REPORT_BOOKING_PAYMENT_FIND_BY_COMPANY_STATUS_DATES: (
    company_id: number,
    status_id: number,
    startDate: string,
    endDate: string
  ) =>
    CONSTANT.DISPATCHER_BOOKING_PREFIX +
    `/booking/allDetailsByDates/${company_id}/${status_id}?&startDate=${startDate}&endDate=${endDate}`,

  REQUEST_REPORT_DOWNLOAD_BOOKING_PAYMENT_FIND_BY_COMPANY_STATUS_DATES: (
    company_id: number,
    status_id: number,
    startDate: string,
    endDate: string,
    extension: string
  ) =>
    CONSTANT.DISPATCHER_DOCUMENTS_PREFIX +
    `/booking/report/allDetailsByDates/${company_id}/${status_id}/${extension}?&startDate=${startDate}&endDate=${endDate}`,

  /** report booking capacity */
  REQUEST_REPORT_BOOKING_CAPACITY_FIND_BY_COMPANY_DATES: (
    company_id: number,
    date: string,
    area_id: number,
    area_detail_id?: number
  ) =>
    CONSTANT.DISPATCHER_BOOKING_PREFIX +
    `/booking/bookingCapacityByDate/${company_id}/${area_id}?&date=${date}${area_detail_id ? "&areaDetailId=" + area_detail_id : ""
    }`,

  REQUEST_REPORT_DOWNLOAD_BOOKING_CAPACITY_FIND_BY_COMPANY_DATES: (
    company_id: number,
    date: string,
    area_id: number,
    extension: string,
    area_detail_id?: number
  ) =>
    CONSTANT.DISPATCHER_DOCUMENTS_PREFIX +
    `/booking/report/bookingCapacityByDate/${company_id}/${area_id}/${extension}?&date=${date}${area_detail_id ? "&areaDetailId=" + area_detail_id : ""
    }`,

  /** area details urls */
  REQUEST_AREA_CREATE: (company_id: string) =>
    CONSTANT.DISPATCHER_BOOKING_PREFIX +
    `/area/detail/?company_id=${company_id}`,
  REQUEST_AREA_UPDATE: (id: string, company_id: string) =>
    CONSTANT.DISPATCHER_BOOKING_PREFIX +
    `/area/detail/?company_id=${company_id}&id=${id}`,
  REQUEST_AREA_DELETE: (company_id: string, id: string) =>
    CONSTANT.DISPATCHER_BOOKING_PREFIX +
    `/area/detail/?company_id=${company_id}&id=${id}`,

  /** Role urls */
  REQUEST_ROLE: (id: string | null, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/all`,
  REQUEST_LIST_ROLE: (filter: string, page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/role/all/${page}/${size}${filter ? "?filter=" + filter : ""}`,
  REQUEST_ROLE2: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/${id ? id : ""}`,
  REQUEST_ROLE_BY_ID: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/${id ? id : ""}`,
  REQUEST_ROLE_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/save`,
  REQUEST_ROLE_UPDATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/`,
  REQUEST_ROLE_DELETE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/delete`,

  /** location urls */
  REQUEST_LOCATION: () =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/province/all`,
  REQUEST_CANTON: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/canton/findAllByProvinceId/${id ? id : ""}/`,
  REQUEST_DISTRICT: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/district/findAllByCantonId/${id ? id : ""}/`,
  REQUEST_ZONE: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/zone/all`,

  /** routes urls */
  REQUEST_ROUTE_ALL: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/route/all`,
  REQUEST_ROUTE: (page: any, size: any, filter: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/route/all/${page}/${size}${filter ? `?filter=${filter}` : ""}`,
  REQUEST_ROUTE_ZONE: () =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/route/allZoneNames`,
  REQUEST_ROUTE_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/route/save`,
  REQUEST_ROUTE_DELETE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/route/delete`,

  /** Company urls */
  REQUEST_COMPANY: (page: any, size: any, filter: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/company/all/${page}/${size}${filter ? `?filter=${filter}` : ""}`,

  REQUEST_COMPANY_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/save`,
  REQUEST_COMPANY_DELETE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/delete`,

  REQUEST_COMPANY_PRICE_ZONE: (page: any, size: any, id: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/costCompanyZone/allByCompanyId/${id}/${page}/${size}`,
  REQUEST_COMPANY_CREATE_ZONE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/costCompanyZone/save`,

  /** Inventory urls */
  REQUEST_INVENTORY: (page: any, size: any, filter: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/inventory/all/${page}/${size}${filter ? `?filter=${filter}` : ""}`,

  REQUEST_INVENTORY_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/inventory/save`,
  REQUEST_INVENTORY_DELETE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/inventory/delete`,

  /** Carriage urls */
  REQUEST_CARRIAGE_DELETE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/carriage/delete`,

  /** Country urls */
  REQUEST_COUNTRY: (page: any | null, size: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/countries/list/?page=${page}&size=${size}`,
  REQUEST_COUNTRY_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/countries/create`,
  REQUEST_COUNTRY_UPDATE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/countries/${id ? id : ""}`,
  REQUEST_COUNTRY_DELETE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/countries/${id ? id : ""}`,
  REQUEST_COUNTRY_AVAILABLE: (name: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/countries/available?name=${name ? name : ""}`,

  /** Report urls */
  REQUEST_REPORT_ORDERS: (
    page: any,
    size: any,
    startDate: any,
    endDate: any,
    company?: any
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/clientOrder/ordersByCompanyReport/${startDate}/${endDate}/${page}/${size}${company ? "?companyId=" + company : ""
    }`,

  REQUEST_REPORT_ORDER: (
    page: any,
    size: any,
    startDate: any,
    endDate: any,
    company?: any
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/clientOrder/orderByCompanyReport/${startDate}/${endDate}/${page}/${size}${company ? "?companyId=" + company : ""
    }`,

  REQUEST_REPORT_ORDERS_EXCEL: (
    page: any,
    size: any,
    startDate: any,
    endDate: any,
    company?: any
  ) =>
    CONSTANT.DISPATCHER_REPORT_PREFIX +
    `/excel/ordersByCompanyReport/${startDate}/${endDate}${company ? "?companyId=" + company : ""
    }`,

  REQUEST_REPORT_DRIVERS: (
    page: any,
    size: any,
    startDate: any,
    endDate: any,
    company?: any
  ) =>
    CONSTANT.DISPATCHER_REPORT_PREFIX +
    `/courierReport/filter/?page=${page}&size=${size}${company ? "&courier=" + company : ""
    }&startDate=${startDate}&endDate=${endDate}`,

  REQUEST_REPORT_DRIVERS_EXCEL: (
    page: any,
    size: any,
    startDate: any,
    endDate: any,
    company?: any
  ) =>
    CONSTANT.DISPATCHER_REPORT_PREFIX +
    `/courierReport/excel/filter/?${company
      ? "courier=" +
      company +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate
      : "?startDate=" + startDate + "&endDate=" + endDate
    }`,

  REQUEST_UPLOAD_EXCEL: () =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/clientOrder/uploadExcelFile`,

  /** Rubro urls */

  // perfil-maintenance/dataTablesJuridic/
  /* REQUEST_RUBRO_LIST: (page:number,size:number) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/dataTablesJuridic/?page=${page}&size=${size}`, */
  REQUEST_RUBRO_LIST: (page: number, size: number, filter?: string) =>
    CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX +
    `/dataTablesJuridic/${filter !== ""
      ? "{description}?tableName=" + filter
      : "?page=" + page + "&size=" + size
    }`,
  REQUEST_RUBRO_WITH_URL: (url: string, size: number, page: number) =>
    CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX +
    `/${url}/?page=${page}&size=${size}&type=j`,
  REQUEST_CREATE_RUBRO: (url: string) =>
    CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/${url}/create`,
  REQUEST_UPDATE_RUBRO: (url: string) =>
    CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/${url}/`,
  REQUEST_DELETE_RUBRO: (url: string, id: number) =>
    CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/${url}/${id}`,

  // perfil-investment/

  REQUEST_LIST_INVESTMENT: (page: number, size: number) =>
    CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX +
    `/profile/users?page=${page}&size=${size}`,
  REQUEST_INVESTMENT_PROFILE_WITH_ID: (id: number) =>
    CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + `/profile/${id}`,
  REQUEST_INVESTMENT_PROFILE_CALCULATE: (id: number) =>
    CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + `/profile/calculate/${id}`,
  /** EmailRole urls */
  REQUEST_EMAIL_ROLE: (
    roleID?: string | null,
    filter?: string | null,
    currentPage?: number,
    size?: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/email/${roleID ? roleID + "/" : ""}?${filter ? "filter=" + filter + "&" : ""
    }page=${currentPage}&size=${size}`,
  REQUEST_EMAIL_ROLE_ID: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/findById/${id ? id : ""}`,
  DELETE_REQUEST_EMAIL_ROLE_ID: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/${id ? id : ""}`,
  REQUEST_EMAIL_ROLE_CU: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/`,

  /** DocumentRole urls */
  REQUEST_DOCUMENT_ROLE_LIST: (
    filter: string | null,
    page: number,
    size: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/documentsRole/list/?${filter ? "filter=" + filter + "&" : ""
    }page=${page}&size=${size}`,
  REQUEST_DOCUMENT_ROLE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documentsRole/${id ? id : ""}`,

  /** document */
  REQUEST_DOCUMENT_LIST: (page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/documents/list/?page=${page}&size=${size}`,
  REQUEST_DOCUMENT: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/${id ? id : ""}`,
  REQUEST_DOCUMENT_UPD: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/{id}`,

  /** authority mobile */
  REQUEST_AUTHORITY_MOBILE_LIST: (filter: string, page: number, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority/allByPlatform/${page}/${size}?${filter ? "filter=" + filter + "&" : ""
    }${filter ? "&platform=mobile" : "platform=mobile"}`,

  REQUEST_AUTHORITY_NO_ADDED_BY_ROLE_MOBILE_LIST: (
    roleId: number,
    page: number,
    size: number,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority/${roleId}/${page}/${size}?${filter ? "filter=" + filter + "&" : ""
    }${filter ? "&platform=mobile" : "platform=mobile"}`,

  /** authorityByRoleMobile */
  REQUEST_AUTHORITY_BY_ROLE_MOBILE_LIST: (
    roleId: string,
    page: number,
    size: number,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority-role/allByPlatformAndRoleId/${page}/${size}/${roleId}?${filter ? "filter=" + filter + "&" : ""
    }${filter ? "&platform=mobile" : "platform=mobile"}`,

  /** authority */
  REQUEST_AUTHORITY_LIST: (filter: string, page: number, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority/allByPlatform/${page}/${size}?${filter ? "filter=" + filter + "&" : ""
    }${filter ? "&platform=web" : "platform=web"}`,
  REQUEST_AUTHORITY: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/${id ? id : ""}`,
  REQUEST_AUTHORITY_NO_ADDED_BY_ROLE_LIST: (
    roleId: number,
    page: number,
    size: number,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority/${roleId}/${page}/${size}?${filter ? "filter=" + filter + "&" : ""
    }${filter ? "&platform=web" : "platform=web"}`,

  /** authorityByRole */
  REQUEST_AUTHORITY_BY_ROLE_LIST: (
    roleId: string,
    page: number,
    size: number,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority-role/allByPlatformAndRoleId/${page}/${size}/${roleId}?${filter ? "filter=" + filter + "&" : ""
    }${filter ? "&platform=web" : "platform=web"}`,

  REQUEST_AUTHORITY_BY_ROLE: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${id ? id : ""}`,

  REQUEST_CLIENT_LIST: (
    endDate: any,
    page: number,
    size: string,
    startDate: any
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/client/{page}/{size}/?endDate=${endDate}&page=${page}&size=${size}&startDate=${startDate}`,
  REQUEST_CLIENT: (id: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/${id ? id : ""}`,
  /* REQUEST_STORES_CLIENT: (id:string, page:number, size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/stores/${id ? id : ''}/${page}/${size}`,   */
  REQUEST_STORES_CLIENT: (id: string, page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/client/stores/${id ? id : ""}?page=${page}&size=${size}`,

  /**Dashboard */
  REQUEST_TOTAL_USERS: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/dashboard/total-users/${id !== 1 ? "?companyId=" + id : ""}`,
  REQUEST_TOTAL_COMPANY:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/total-company/`,
  REQUEST_COMPANY_BY_CATEGORY:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/company-by-category/`,
  REQUEST_BOOKING_BY_MONTH: (id: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/dashboard/booking-by-month/?companyId=${id}`,
  REQUEST_BOOKING_BY_AREA: (id: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/dashboard/booking-by-area?companyId=${id}`,
  REQUEST_BOOKING_APPROVED: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/dashboard/booking-approved/${id !== 1 ? "?companyId=" + id : ""}`,

  REQUEST_TOTAL_SALES: (startDate: string, endDate: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/clientOrder/countByRange/)${startDate}/${endDate}`,

  REQUEST_COVERAGE_PERCENTAGE: () =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/route/coveragePercentage`,

  REQUEST_SALES_BY_DAY: (year: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/clientOrder/countByDay/${year}`,

  REQUEST_AVE_RANGE: (startDate: string, endDate: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/clientOrder/countAveByRange/${startDate}/${endDate}`,

  REQUEST_AVE_HOURS: (startDate: string, endDate: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/deliveryCourier/calculateDeliveryHours/${startDate}/${endDate}`,

  REQUEST_SALES_BY_MONTH: (year: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/clientOrder/countByYearGroupByMonth/${year}`,

  REQUEST_SALES_BY_CLIENTS: (year: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/clientOrder/countByYear/${year}`,

  /**Dashboard Report */

  REQUEST_SUCCESS_DELIVERY: (startDate: string, endDate: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/deliveryCourier/calculateSuccessfulDeliveredPercentage/${startDate}/${endDate}`,

  REQUEST_REJECT_DELIVERY: (startDate: string, endDate: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/deliveryCourier/calculateRejectedDeliveredPercentage/${startDate}/${endDate}`,

  REQUEST_DELIVERY_STATICS: (startDate: string, endDate: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/deliveryCourier/deliveryStatistics/${startDate}/${endDate}`,

  REQUEST_TIMELINESS_PERCENTAGE: (startDate: string, endDate: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/deliveryCourier/timelinessPercentage/${startDate}/${endDate}`,

  REQUEST_COVERAGE_EXCEL: () =>
    CONSTANT.DISPATCHER_REPORT_PREFIX + `/excel/coverage`,

  REQUEST_PENDING_RATE: (startDate: string, endDate: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/deliveryCourier/pendingRate/${startDate}/${endDate}`,

  // Associated

  REQUEST_ASSOCIATED_LIST: (
    page: number,
    size: number,
    companyId: any,
    filter?: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/associated/filter/?page=${page}&size=${size}&companyId=${companyId}${filter ? `&filter=${filter}` : ""
    }`,
  REQUEST_ALLASSOCIATED_LIST: (companyId: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/associated/list/?companyId=${companyId}`,
  REQUEST_ASSOCIATED_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/associated`,
  REQUEST_ASSOCIATED_UPDATE: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/associated/${id}`,
  REQUEST_ASSOCIATED_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/associated/${id}`,

  /** Apartment urls */
  REQUEST_APARTMENT: (
    page: any | null,
    size: number,
    companyId: number,
    name: string,
    status: string,
    user: string,
    userId: number | null
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/apartment?companyId=${companyId}&page=${page}&size=${size}&name=${name}&status=${status}&user=${user}&userId=${userId}`,
  REQUEST_APARTMENT_BY_ID: (
    page: any | null,
    size: number,
    companyId: number,
    userId: number
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/apartment?companyId=${companyId}&page=${page}&size=${size}&userId=${userId}`,
  REQUEST_APARTMENT_CREATE: (idUser: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/apartment/${idUser}`,
  REQUEST_APARTMENT_UPDATE: (idApartment: number, idUser: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/apartment/${idApartment}/${idUser}`,
  REQUEST_APARTMENT_DELETE: (id: number | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/apartment/${id ? id : ""}`,

  /** Apartment Pending urls */
  REQUEST_APARTMENT_PENDING: (
    page: any | null,
    size: number,
    companyId: number,
    apartment: string,
    state: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/apartmentPendings?companyId=${companyId}&page=${page}&size=${size}&apartment=${apartment}&state=${state}`,
  REQUEST_APARTMENT_PENDING_CREATE: (idApartment: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/apartmentPendings/${idApartment}`,
  REQUEST_APARTMENT_PENDING_UPDATE: (idPending: number, idApartment: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/apartmentPendings/${idPending}/${idApartment}`,
  REQUEST_APARTMENT_PENDING_DELETE: (id: number | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/apartmentPendings/${id ? id : ""}`,

  // Guests

  REQUEST_GUESTS_LIST: (page: number, size: number, companyId: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/guest?page=${page}&size=${size}&companyId=${companyId}`,
  REQUEST_GUESTS_ADD: (companyId: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/guest?companyId=${companyId}`,
  REQUEST_GUESTS_DELETE: (id: number, companyId: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/guest/${id}?companyId=${companyId}`,
  REQUEST_GUESTS_UPDATE: (id: number, companyId: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/guest/${id}?companyId=${companyId}`,

  // Messages
  REQUEST_MESSAGES_LIST: (
    page: number,
    size: number,
    companyId: number,
    adminType: boolean,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/message/filter?page=${page}&size=${size}&companyId=${companyId}&adminType=${adminType}&filter=${filter}`,
  REQUEST_MESSAGES_LIST_BY_APARTMENT: (
    page: number,
    size: number,
    companyId: number,
    adminType: boolean,
    filter: string,
    apartment: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/message/filter?page=${page}&size=${size}&companyId=${companyId}&adminType=${adminType}&apartment=${apartment}&filter=${filter}`,
  REQUEST_MESSAGE_ADD: (companyId: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/message?companyId=${companyId}`,
  REQUEST_MESSAGE_DELETE: (id: number, companyId: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/message/${id}?companyId=${companyId}`,
  REQUEST_MESSAGE_UPDATE: (id: number, companyId: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/message/${id}?companyId=${companyId}`,
  REQUEST_MESSAGE_READ: (id: number, companyId: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/message/${id}/markAsRead?companyId=${companyId}`,

  //News
  REQUEST_NEWS_LIST: (page: number, size: string, sort: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/news/list/?page=${page}&size=${size}&sort=${sort}`,
  REQUEST_NEWS_FILTER: (
    page: number,
    size: string,
    companyId: number,
    filter: string,
    status?: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/news/filter/?page=${page}&size=${size}&companyId=${companyId}${filter !== "" ? "&filter=" + filter : ""
    }${status !== "" ? "&status=" + status : ""}`,
  /* REQUEST_NEWS_FILTER: (page:number, size: string, sort: string, filter:string, status?: string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/news/filter/?page=${page}&size=${size}&sort=${sort}&filter=${filter}${status !== "" ? "&status="+status : ""}`, */
  REQUEST_NEWS: (option: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/news/${option}`,

  //News Categories
  REQUEST_CATEGORIES: (
    page: number,
    size: string,
    companyId: number,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/category/filter/?page=${page}&size=${size}&companyId=${companyId}${filter !== "" ? "&filter=" + filter : ""
    }`,
  REQUEST_CATEGORIES_CRUD: (option: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/category/${option}`,

  // Currency
  REQUEST_CURRENCIES: (page: number, size: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/currency/list/?page=${page}&size=${size}`,

  /** Pay Method urls */
  REQUEST_PAY_METHOD_LIST: (company: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/companyCard/payMethods?companyId=${company}`,

  REQUEST_PAY_METHOD_DELETE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/${id ? id : ""}`,
  REQUEST_PAY_METHOD_BYID: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/company/filter/?page=0&size=10&sort=desc&filter=${id ? id : ""}`,

  /** Card Method urls */
  REQUEST_PAY_METHOD_CREATE_CARD:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/companyCard`,

  REQUEST_PAY_METHOD_UPDATE_CARD: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/companyCard/${id ? id : ""}`,

  REQUEST_PAY_METHOD_DELETE_CARD: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/companyCard/${id ? id : ""}`,

  /** Account Method urls */
  REQUEST_PAY_METHOD_CREATE_ACCOUNT:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/companyAccount`,

  REQUEST_PAY_METHOD_UPDATE_ACCOUNT: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/companyAccount/${id ? id : ""}`,

  REQUEST_PAY_METHOD_DELETE_ACCOUNT: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/companyAccount/${id ? id : ""}`,

  /** Notifications urls */

  REQUEST_NOTIFICATIONS_LIST: (page: number, size: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/notifications/list/?page=${page}&size=${size}`,

  REQUEST_NOTIFICATIONS_LIST_BY_COMPANY: (
    page: number,
    size: number,
    company: number
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/notifications/filter/?page=${page}&size=${size}&company=${company}`,

  REQUEST_NOTIFICATIONS_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/notifications/create`,

  REQUEST_NOTIFICATIONS_UPDATE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/notifications/${id ? id : ""}`,

  REQUEST_NOTIFICATIONS_DELETE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/notifications/${id ? id : ""}`,

  /**Plan List url */
  REQUEST_PLAN_LIST_LIST: (page: number, size: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/PlanList/list/?page=${page}&size=${size}`,

  /**Drivers transport url */
  REQUEST_COLOR_LIST: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/color/all`,
  REQUEST_BRAND_LIST:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/carriageBrand/all`,
  REQUEST_TRANSPORT_LIST:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/meansOfTransport/all`,

  REQUEST_BANK_FILTER: (page?: number, size?: number, filter?: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/bank/all/${page + "/" + size}`,
  REQUEST_BANK_LIST: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/bank/all`,
  REQUEST_BANK_ID: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/bank/${id}`,
  REQUEST_BANK_SAVE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/bank/save`,
  REQUEST_BANK_DELETE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/bank/delete`,

  /**
   ***** Orders url
   **/
  REQUEST_ORDER_FILTER: (page: number, size: number, filter: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/orderRating/all/${page + "/" + size}${filter !== "" ? "?filter=" + filter : ""
    }`,
  TRAKING_ORDER: (clientOrderId: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/trackingOrder/findByOrderId/${clientOrderId}`,
  REQUEST_LAT_LNGT_STRING: (address: string, token: string) =>
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?country=CR&access_token=${token}`,

  /**
   ***** Client Orders url
   **/
  FIND_HISTORY_GROUP_BY_PACKAGE_STATUS: (
    startDate: string,
    endDate: string,
    page: number,
    size: number,
    companyId?: number
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/clientOrder/findHistoryGroupByPackageStatus/${startDate}/${endDate}/${page}/${size}${companyId !== 0 ? "?companyId=" + companyId : ""
    }`,
    FIND_HISTORY_BY_PACKAGE_STATUS: (
      packageStatus: number,
      startDate: string,
      endDate: string,
      page: number,
      size: number,
      filter: string,
      companyId?: number
    ) =>
      CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
      `/clientOrder/findHistoryByPackageStatus/${packageStatus}/${startDate}/${endDate}/${page}/${size}` +
      `${companyId ? "?" + "companyId=" + companyId : ""}` + 
      `${companyId && filter ? "&" : companyId ? "" : "?"}` + 
      `${filter ? "filter=" + filter : ""}`, 
    
  SAVE_CLIENT_ORDER:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/clientOrder/save`,
  DELETE_CLIENT_ORDER:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/clientOrder/delete`,

  /**
   ***** Order Detail url
   **/
  ORDER_DETAIL_SAVESERIALS:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/orderDetail/saveSerials`,
  ORDER_DETAIL_ID: (id: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/orderDetail/${id}`,

  ORDER_DETAIL_FIND_BY_ID_CLIENT_ORDER: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/orderDetail/findByIdClientOrder/${id}`,

  ORDER_DETAIL_DELETE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/orderDetail/delete`,

  ORDER_DETAIL_SAVE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/orderDetail/save`,

  /**
   ***** Order Status
   **/
  ORDER_STATUS_SAVE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/orderStatus/save`,
  ORDER_STATUS: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/packageStatus/all`,

  /**
   ***** Blob Storage
   **/
  BLOB_STORAGE_UPLOAD: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/store/upload`,
  DELETE_BLOB_STORAGE: (fileName: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/store/delete?fileName=${fileName}`,

  /**
   ***** Order documents
   **/
  SAVE_ORDER_DOCUMENTS:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/orderDocument/save`,
  DELETE_ORDER_DOCUMENTS:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/orderDocument/delete`,
};

//https://dispatcher-asap.bitcode-enterprise.dev/v1/maintenance/orderDetail/654
