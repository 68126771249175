export const CONSTANT = {
  DISPATCHER_SECURITY_PREFIX:"/security",
  DISPATCHER_USER_PREFIX:"/users",
  DISPATCHER_MAINTENANCE_PREFIX: "/maintenance",

  /** HEADER TYPE */
  HEADER_TYPE: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  HEADER_TYPE_DOCUMENTS: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data;",
  },

  /** TYPE MESSAGE */
  SUCCESS_MSG: 1,
  ERROR_MSG: 2,
  WARNING_MSG: 3,
}



export const URL = {
  /** HOME PATH */
  LOGIN_TWOFACTORCODE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/login/twofactor",
  LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/autenticate",
  LOGIN_RECOVERY_PASS_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/recoverPasswordAdmin",
  LOGIN_CHANGE_PASS_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/changePassword",
  LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/activate",
  SESSIONVALIDATE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",
  CANCEL_SUBSCRIPTION_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/unsubscribe",
}