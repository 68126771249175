import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es.js";
import { CONSTANT } from "../../utils/Constants.js";
import { UrlBase } from "../../url/Urls.js";
import axios from "axios";

export const getRequestOrdersRating  = async (page: number, filter: string)=> {
  try {
    const response = await privateFetch.get(URL.REQUEST_ORDER_FILTER(page, UrlBase.registration_x_page, filter));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getTrakingOrder  = async (clientOrderId: number)=> {
  try {
    const response = await privateFetch.get(URL.TRAKING_ORDER(clientOrderId));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getLatAndLngtAddress  = async (address: string, token:string)=> {
  try {
    const response = await axios.get(URL.REQUEST_LAT_LNGT_STRING(address, token));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

