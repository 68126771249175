import { URL } from "../utils/UrlConstants";
import { privateFetch } from "../utils/CustomFetch.js";
import { properties } from "../utils/Properties_es";
import { CONSTANT } from "../utils/UrlContants";
import { UrlBase } from "../utils/Urls";

export const getRequestBank = async (page?: any, filter?: any) => {
  console.log(page)
  let responseData = {};
  const params = new URLSearchParams([["filter", filter]]);  
    await privateFetch
    .get(
      URL.REQUEST_BANK_FILTER(page,UrlBase.registration_x_page), {params}  
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
     
    });
    return responseData;   
};

export const getRequestBankList = async () => {
  let responseData = {}; 
    await privateFetch
    .get(
      URL.REQUEST_BANK_LIST 
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
     
    });
    return responseData;   
};

export const findByIdRequest = async (id: number) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_BANK_ID(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const createBank = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    name: data.name    
  };
  await privateFetch
    .post(URL.REQUEST_BANK_SAVE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
      console.log(error)
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  const requestBody = {
    id: id   
  };
  
  await privateFetch
    .delete(URL.REQUEST_BANK_DELETE, { data: requestBody })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        properties.com_parval_label_request_delete
      );
    });
  return responseData;
};
