import { useState } from "react";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Stack,
  useTheme,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import FancyTooltip from "./FancyTooltip";
import { PenIcon, TrashIcon } from "../svgicons/SvgIcons";
import { grey } from "@mui/material/colors";

// const feature = [
//   {
//     key: "Cuenta bancaria",
//     description: "CR05012602001026284066",
//   },
//   {
//     key: "Contrato",
//     description: "Temporal",
//   },
//   {
//     key: "Placa",
//     description: "AA040IG",
//   },
// ];

const CollapseDrivers = ({ data, handleOpenModal }: any) => {
  console.log(data)
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const feature = [
    {
      key: "Cuenta bancaria",
      description: data.accountNumber ?? "No definido",
    },
    {
      key: "Contrato",
      description: data.contractType ?? "No definido",
    },
    {
      key: "Placa",
      description: data.carriages?.[0]?.plate ?? "No definido",
    },
    // Puedes agregar más características si es necesario
  ];

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell
          component="th"
          scope="row"
          align="left"
          sx={{ display: "flex", alignItems: "center", borderBottom: 0 }}
          onClick={() => setOpen(!open)}
        >
          <IconButton aria-label="expand row">
            {open ? (
              <ArrowDropDownIcon sx={{ fontSize: "1.5rem" }} />
            ) : (
              <ArrowRightIcon sx={{ fontSize: "1.5rem" }} />
            )}
          </IconButton>
          <Typography
            variant="body1"
            color="initial"
            sx={{ fontWeight: 600, ml: 2 }}
          >
            {data.firstName + " " + data.lastName}
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          sx={{ borderBottom: 0 }}
          onClick={() => setOpen(!open)}
        >
          <Typography variant="body1" color="initial" sx={{ fontWeight: 600 }}>
            {data?.email ?? "No definido"}
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          sx={{ borderBottom: 0 }}
          onClick={() => setOpen(!open)}
        >
          <Typography
            variant="body1"
            color="initial"
            sx={{ fontWeight: 600, display: "flex", alignItems: "center" }}
          >
            <PhoneIcon sx={{ mr: 1, transform: "translateY(-1px)" }} />
            {data.phone ?? "+1 201 384 5567"}
          </Typography>
        </TableCell>
        {/* <TableCell
          align="right"
          sx={{ borderBottom: 0 }}
          onClick={() => setOpen(!open)}
        >
          <Button
            variant="contained"
            color={`${data.status === 0 ? "success" : "error"}`}
          >
            {data.status === 0 ? "Disponible" : "No disponible"}
          </Button>
        </TableCell> */}
        <TableCell align="center">
          <FancyTooltip title="Editar" placement="top">
            <IconButton
              aria-label="edit"
              component="label"
              color="primary"
              onClick={handleOpenModal}
              data-name="update"
              data-id={data.id}
            >
              <PenIcon />
            </IconButton>
          </FancyTooltip>
        </TableCell>
        <TableCell align="center">
          <FancyTooltip title="Eliminar" placement="top">
            <IconButton
              aria-label="delete"
              component="label"
              color="error"
              onClick={handleOpenModal}
              data-name="delete"
              data-id={data.id}
            >
              <TrashIcon />
            </IconButton>
          </FancyTooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={5}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stack
              direction={{
                xs: "column-reverse",
                md: "row",
              }}
              sx={{
                paddingBottom: 4,
                borderTop: "1px solid #dee2e6",
              }}
            >
              <List
                sx={{
                  width: "100%",
                }}
              >
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    "&:hover": {
                      background: "transparent!important",
                    },
                    paddingBottom: 0,
                  }}
                >
                  <ListItemText
                    primary="Identificación"
                    secondary={`${data.identification}`}
                    sx={{
                      "& *": {
                        color: `${theme.palette.text.primary}!important`,
                        lineHeight: "1.5",
                        fontSize: "0.9rem",
                      },
                      "& span": {
                        fontWeight: "700!important",
                      },
                      display: "flex",
                      flexDirection: {
                        sx: "column",
                        md: "row",
                      },
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  />
                </ListItem>
                {feature.map((f: any, i: number) => (
                  <>
                    <ListItem
                      alignItems="flex-start"
                      sx={{
                        "&:hover": {
                          background: "transparent!important",
                        },
                        paddingBottom: 0,
                      }}
                      key={i}
                    >
                      <ListItemText
                        primary={`${f.key}:`}
                        secondary={`${f.description}`}
                        sx={{
                          "& *": {
                            color: `${theme.palette.text.primary}!important`,
                            lineHeight: "1.5",
                            fontSize: "0.9rem",
                          },
                          "& span": {
                            fontWeight: "700!important",
                          },
                          display: "flex",
                          flexDirection: {
                            sx: "column",
                            md: "row",
                          },
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      />
                    </ListItem>
                  </>
                ))}
              </List>
              <ListItemAvatar sx={{ mt: 4 }}>
                <Avatar
                  sx={{
                    width: "80px",
                    height: "80px",
                  }}
                >
                  <AccountBoxIcon sx={{ fontSize: "4rem" }} />
                </Avatar>
              </ListItemAvatar>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CollapseDrivers;
