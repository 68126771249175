import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Divider,
  FormHelperText,
  FormLabel,
  useTheme,
  Box,
  IconButton,
  Dialog,
  InputAdornment,
} from "@mui/material";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import * as Yup from "yup";
import { formSchemaOptionsName } from "../../types/Utils";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useApp } from "../../hooks/useApp";
import SubTilteDialog from "./SubTilteDialog";
import { PatternFormat } from "react-number-format";
import FancyDialogActions from "./FancyDialogActions";
import { deleteCarriageRequest } from "../../services/carriage/CarriageService";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import FancyTooltip from "./FancyTooltip";
import Swal from "sweetalert2";

interface AddOrUpdateUserTypes {
  id?: number;
  email: string;
  identification: string;
  firstName: string;
  lastName: string;
  password?: string;
  phone: string;
  status: string;
  bank: {
    id: number;
    name: string;
  };
  accountNumber: string;
  agreement: string;
  numberLicense: string;
  licenseLevel: string;
  deliveryCourierImage?: File[];
  carriage: {
    id?: number;
    plate: string;
    carriageBrand: {
      id: number;
      name: string;
    };
    year: string;
    meanOfTransport: {
      id: number;
      name: string;
    };
    color: {
      id: number;
      name: string;
    };
  }[];
  idCompany: {
    id: number;
    name: string;
  };
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType: string;
  bankData: {
    id: number;
    name: string;
  }[];
  colorData: {
    id: number;
    name: string;
  }[];
  brandData: {
    id: number;
    name: string;
  }[];
  transportData: {
    id: number;
    name: string;
  }[];
  companyData: {
    id: number;
    name: string;
  }[];
}
const AddOrUpdateDriver = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  bankData,
  colorData,
  brandData,
  transportData,
  companyData,
}: DialogProps) => {
  const theme = useTheme();
  //Validación de datos
  const validation = Yup.object().shape({
    email: Yup.string()
      .required("Campo es requerido")
      .email("Email es invalido"),
    identification: Yup.string().required("Campo es requerido"),
    firstName: Yup.string().required("Campo es requerido"),
    lastName: Yup.string().required("Campo es requerido"),
    password: Yup.string().when(modalType, () => {
      return modalType === "create"
        ? Yup.string()
            .required("Contraseña es requerida")
            .min(6, "Contraseña debe tener al menos 8 caracteres")
            .test(
              "password",
              `Formato no es correcto, debe contener al menos 1 mayúsculas, 1 minúsculas, 1 número y 1 carácter especial`,
              (value: any) =>
                value.match(
                  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/
                )?.length > 0
            )
        : Yup.string();
    }),
    phone: Yup.string()
      .required("Campo es requerido")
      .max(20, "Número es muy largo, máximo 20 caracteres"),
    status: Yup.string(),
    bank: Yup.object()
      .shape({
        id: Yup.string(),
        name: Yup.string(),
      })
      .typeError("Campo es requerido"),
    idCompany: Yup.object()
      .shape({
        id: Yup.string().required("Campo es requerido"),
        name: Yup.string().required("Campo es requerido"),
      })
      .required("Campo es requerido")
      .nullable(),
    accountNumber: Yup.string()
      .required("Campo es requerido")
      .max(20, "Número es muy largo, máximo 20 caracteres"),
    agreement: Yup.string().required("Campo es requerido"),
    numberLicense: Yup.string()
      .required("Campo es requerido")
      .max(20, "Número es muy largo, máximo 20 caracteres"),
    licenseLevel: Yup.string().required("Campo es requerido"),
    carriage: Yup.array()
      .of(
        Yup.object().shape({
          plate: Yup.string().required("Campo es requerido"),
          carriageBrand: Yup.object().shape({
            id: Yup.number().required("La marca es requerida"),
            name: Yup.string().required("Campo es requerido"),
          }),
          meanOfTransport: Yup.object().shape({
            id: Yup.number().required("El tipo de transporte es requerido"),
            name: Yup.string().required("Campo es requerido"),
          }),
          year: Yup.string().required("El año es requerido"),
          color: Yup.object().shape({
            id: Yup.number().required("El color es requerido"),
            name: Yup.string().required("Campo es requerido"),
          }),
        })
      )
      .min(1, "Debe agregar al menos un método de transporte"),
  });

  console.log(data);

  const defaultValues = {
    id: data?.id ?? 0,
    email: data?.email ?? "",
    identification: data?.identification ?? "",
    firstName: data?.firstName ?? "",
    lastName: data?.lastName ?? "",
    password: data?.password ?? "",
    phone: data?.phone ?? "",
    status: data?.status ?? "",
    activationCode: data?.activationCode ?? "",
    bank: data?.bank ?? "",
    accountNumber: data?.accountNumber ?? "",
    agreement: data?.contractType ?? "",
    numberLicense: data?.licenseNumber ?? "",
    licenseLevel: data?.licenceLevel ?? "",
    deliveryCourierImage: [] as File[],
    idCompany: data?.company?.id
      ? {
          id: data?.company.id,
          name:
            companyData.find((f: any) => f.id === data?.company.id)?.name ?? "",
        }
      : { id: 0, name: "" },
    carriage: data?.carriages?.length
      ? data.carriages.map((carriage: any) => ({
          id: carriage.id ?? 0,
          plate: carriage.plate ?? "",
          carriageBrand: carriage.carriageBrand ?? { id: 0, name: "" },
          year: carriage.year ?? "",
          meanOfTransport: carriage.meanOfTransport ?? { id: 0, name: "" },
          color: carriage.color ?? { id: 0, name: "" },
        }))
      : [],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<AddOrUpdateUserTypes>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const {
    fields: carriageField,
    append: carriageAppend,
    remove: carriageRemove,
  } = useFieldArray({
    control,
    name: "carriage",
    keyName: "key",
    rules: {
      minLength: 1,
    },
  });

  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
    handleDataCompanies,
  } = useApp();

  // const [images, setImages] = useState<any>([]);
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  const [images, setImages] = useState<any>(
    data?.imageFileNameUrl
      ? [{ name: "Imagen actual", url: data.imageFileNameUrl }]
      : []
  );

  const handleRemove = async (carriage: any, index: any) => {
    console.log(carriage);
    if (carriage.id) {
      console.log("entra acá");
      try {
        let response: any = await deleteCarriageRequest(carriage.id);

        if (response.deleted) {
          console.log("Carriage eliminado exitosamente del servidor.");
          carriageRemove(index);
        } else {
          console.error("Error al eliminar el transporte del servidor:");
        }
      } catch (error) {
        console.error(
          "Error en la solicitud para eliminar el carriage:",
          error
        );
      }
    } else {
      console.log("entra acá");
      carriageRemove(index);
      console.log("Eliminando carriage localmente.");
    }
  };

  // const handleImageUpload = (event: any) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImages((prev: any) => [
  //         ...prev,
  //         { name: file.name, url: reader.result },
  //       ]);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  //   console.log(file)
  //   setValue(`deliveryCourierImage`, file)
  // };
  const handleImageUpload = (event: any, index: any) => {
    const file = event.target.files[0];
    console.log(file);
    if (file.size >= 9 * 1024 * 1024) {
      Swal.fire({
        icon: "error",
        title: "Archivo inválido",
        text: "El tamaño del archivo debe ser menor a 9MB",
        confirmButtonColor: "#00800A",
      });
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((prev: any) => {
          const updatedImages = [...prev];
          if (index !== undefined) {
            updatedImages[index] = { name: file.name, url: reader.result };
          } else {
            updatedImages.push({ name: file.name, url: reader.result });
          }
          return updatedImages;
        });
      };
      reader.readAsDataURL(file);
    }
    setValue(`deliveryCourierImage`, file);
  };

  console.log(images);

  const handleImageRemove = (index: any) => {
    setImages((prev: any) => prev.filter((_: any, i: any) => i !== index));
  };

  const handleImageClick = (url: string) => {
    setSelectedImage(url);
    setShowImageModal(true);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setSelectedImage(null);
  };

  const triggerFileInput = (index: number) => {
    const input = document.getElementById(
      `image-upload-${index}`
    ) as HTMLInputElement;
    input?.click();
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar mensajero" : "Actualizar mensajero"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <SubTilteDialog title="Datos personales" />
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name={"email"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Correo electrónico"
                    type="mail"
                    variant="standard"
                    value={value}
                    autoComplete="email3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("email")}
                    error={errors.email && Boolean(errors.email)}
                    helperText={errors.email && errors.email.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name={"firstName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="firstName"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("firstName")}
                    error={errors.firstName && Boolean(errors.firstName)}
                    helperText={errors.firstName && errors.firstName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name={"lastName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Apellido"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="lastName"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("lastName")}
                    error={errors.lastName && Boolean(errors.lastName)}
                    helperText={errors.lastName && errors.lastName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                control={control}
                name="idCompany"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={companyData?.map((c: any) => ({
                      id: c.id,
                      name: c.name,
                    }))}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Compañía"
                        variant="standard"
                        error={!!errors.idCompany}
                        helperText={errors.idCompany?.name?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name={"identification"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Identificación"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="identification"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("identification")}
                    error={
                      errors.identification && Boolean(errors.identification)
                    }
                    helperText={
                      errors.identification && errors.identification.message
                    }
                  />
                )}
              />
            </Grid>
            {modalType === "create" && (
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name={"password"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Contraseña"
                      type={showPassword ? "text" : "password"}
                      variant="standard"
                      value={value}
                      autoComplete="off"
                      inputProps={{
                        autoComplete: "no-pass",
                      }}
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("password")}
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name={"phone"}
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormControl sx={{ width: "100%", marginTop: "20px" }}>
                    <FormLabel
                      sx={{
                        color: theme.palette.secondary.dark,
                        position: "absolute",
                        left: "-10px",
                        top: "-26px",
                        transform: "translate(0, -1.5px) scale(0.75)",
                      }}
                    >
                      Teléfono
                    </FormLabel>
                    <PatternFormat
                      className="input-phone"
                      name={name}
                      onPaste={(e: any) => e.preventDefault()}
                      mask="_"
                      format={"####-####"}
                      value={value}
                      onChange={onChange}
                      placeholder={"####-####"}
                    />
                    {errors && (
                      <FormHelperText>{errors.phone?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name={"agreement"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel id="driver-select-label">
                      Tipo de contrato
                    </InputLabel>
                    <Select
                      labelId="driver-select-label"
                      id="driver-select"
                      value={value}
                      {...register("agreement")}
                      label="Seleccionar conductor"
                      variant="standard"
                    >
                      <MenuItem value="Fijo">Fijo</MenuItem>
                      <MenuItem value="Por contrato">Por entrega</MenuItem>
                    </Select>
                    {errors.agreement && (
                      <FormHelperText>
                        {errors.agreement.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <SubTilteDialog title="Datos bancarios" />
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name={"bank"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={bankData}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Nombre del banco"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.bank}
                          helperText={errors.bank && errors.bank.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name={"accountNumber"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="N° Cuenta"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="accountNumber"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("accountNumber")}
                    error={
                      errors.accountNumber && Boolean(errors.accountNumber)
                    }
                    helperText={
                      errors.accountNumber && errors.accountNumber.message
                    }
                  />
                )}
              />
            </Grid>
            <SubTilteDialog title="Datos transporte" />
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name={"numberLicense"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Código licencia"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="numberLicense"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("numberLicense")}
                    error={
                      errors.numberLicense && Boolean(errors.numberLicense)
                    }
                    helperText={
                      errors.numberLicense && errors.numberLicense.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="licenseLevel"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    options={["A1", "A2", "A3", "A4", "B1", "B2", "B3", "B4", "C1", "C2"]}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={value || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Nivel licencia"
                        variant="standard"
                        sx={{ "& input": { pl: "0!important" } }}
                        error={!!errors.licenseLevel}
                        helperText={errors.licenseLevel?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            {errors.carriage && (
              <Typography sx={{ fontWeight: "bold", color: "text.primary" }}>
                {errors.carriage.message}
              </Typography>
            )}
            {carriageField.map((field, i) => {
              return (
                <>
                  <Grid item md={3} sm={6} xs={12} key={field.id}>
                    <Controller
                      name={`carriage.${i}.plate`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Placa"
                          type="text"
                          variant="standard"
                          fullWidth
                          value={value}
                          {...register(`carriage.${i}.plate` as const)}
                          error={errors?.carriage?.[i]?.plate && true}
                          helperText={errors?.carriage?.[i]?.plate?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <Controller
                      name={`carriage.${i}.meanOfTransport`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, newValue: any) => {
                            onChange(newValue);
                          }}
                          options={transportData}
                          getOptionLabel={(option: any) => option.name || ""}
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id
                          }
                          //@ts-ignore
                          value={value}
                          renderInput={(params) => {
                            const inputProps = params.inputProps;
                            inputProps.autoComplete = "new-password";
                            return (
                              <TextField
                                {...params}
                                fullWidth
                                label="Tipo de transporte"
                                variant="standard"
                                sx={{ "& input": { pl: "0!important" } }}
                                error={
                                  errors?.carriage?.[i]?.meanOfTransport && true
                                }
                                helperText={
                                  errors?.carriage?.[i]?.meanOfTransport?.name
                                    ?.message
                                }
                              />
                            );
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <Controller
                      name={`carriage.${i}.carriageBrand`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, newValue: any) => {
                            onChange(newValue);
                          }}
                          options={brandData}
                          getOptionLabel={(option: any) => option.name || ""}
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id
                          }
                          //@ts-ignore
                          value={value}
                          renderInput={(params) => {
                            const inputProps = params.inputProps;
                            inputProps.autoComplete = "new-password";
                            return (
                              <TextField
                                {...params}
                                fullWidth
                                label="Marca"
                                variant="standard"
                                sx={{ "& input": { pl: "0!important" } }}
                                error={
                                  errors?.carriage?.[i]?.carriageBrand && true
                                }
                                helperText={
                                  errors?.carriage?.[i]?.carriageBrand?.name
                                    ?.message
                                }
                              />
                            );
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <Controller
                      name={`carriage.${i}.year`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Año"
                          type="text"
                          variant="standard"
                          fullWidth
                          value={value}
                          {...register(`carriage.${i}.year` as const)}
                          error={errors?.carriage?.[i]?.year && true}
                          helperText={errors?.carriage?.[i]?.year?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <Controller
                      name={`carriage.${i}.color`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, newValue: any) => {
                            onChange(newValue);
                          }}
                          options={colorData}
                          getOptionLabel={(option: any) => option.name || ""}
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id
                          }
                          //@ts-ignore
                          value={value}
                          renderInput={(params) => {
                            const inputProps = params.inputProps;
                            inputProps.autoComplete = "new-password";
                            return (
                              <TextField
                                {...params}
                                fullWidth
                                label="Color"
                                variant="standard"
                                sx={{ "& input": { pl: "0!important" } }}
                                error={errors?.carriage?.[i]?.color && true}
                                helperText={
                                  errors?.carriage?.[i]?.color?.name?.message
                                }
                              />
                            );
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleRemove(field, i)}
                    >
                      Eliminar
                    </Button>
                  </Grid>
                </>
              );
            })}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  carriageAppend({
                    plate: "",
                    carriageBrand: { id: 0, name: "" },
                    meanOfTransport: { id: 0, name: "" },
                    year: "",
                    // status: true,
                    color: { id: 0, name: "" },
                  })
                }
                disabled={carriageField.length >= 2}
              >
                Agregar Transporte
              </Button>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="image-upload">
                <Button
                  variant="contained"
                  color="secondary"
                  component="span"
                  disabled={images.length >= 1}
                >
                  Agregar Imagen
                </Button>
              </label>
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(event) => handleImageUpload(event, undefined)}
                disabled={images.length >= 1}
              />
            </Grid>
            {images.map((image: any, index: number) => (
              <Grid item xs={12} key={index}>
                <Box display="flex" alignItems="center">
                  <img
                    src={image.url}
                    alt={image.name}
                    width="50"
                    height="50"
                    onClick={() => handleImageClick(image.url)}
                    style={{ cursor: "pointer" }}
                  />
                  <Box ml={2} flexGrow={1}>
                    {image.name}
                  </Box>
                  <FancyTooltip title="Actualizar Imagen" placement="top">
                    <IconButton
                      color="error"
                      onClick={() => triggerFileInput(index)}
                    >
                      <UpdateIcon sx={{ fontSize: "1.4rem" }} />
                    </IconButton>
                  </FancyTooltip>
                  <input
                    id={`image-upload-${index}`}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(event) => handleImageUpload(event, index)}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </form>
      </DialogContent>
      <Dialog open={showImageModal} onClose={handleCloseImageModal}>
        <DialogTitle>Imagen Ampliada</DialogTitle>
        <DialogContent>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Ampliación de imagen"
              style={{ maxWidth: "100%" }}
            />
          )}
        </DialogContent>
      </Dialog>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdateDriver;
