import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";

export const getRequestRouteAll = async () => {
  try {
    const response = await privateFetch.get(URL.REQUEST_ROUTE_ALL);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};
export const getRequest = async (page: number,  filter:string,) => {
  try {
    const response = await privateFetch.get(URL.REQUEST_ROUTE(page,UrlBase.registration_x_page,filter));
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestZone = async () => {
  try {
    const response = await privateFetch.get(URL.REQUEST_ROUTE_ZONE());
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getRequestRole = async () => {
  let responseData = {};
  await privateFetch
    .get(
      URL.REQUEST_ROLE2(null)
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      //throw new Error(properties.com_parval_label_role_find_error);
      throw new Error(error);
    });
  return responseData;
};


export const findByIdRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_ROLE(id, UrlBase.registration_x_page), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const findByIdRequestById = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_ROLE_BY_ID(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  let responseData = {};
  // {
  //   id: 0,
  //   zone: { id: 2, name: 'RURAL' },
  //   province: { id: 0, name: 'Alajuela' },
  //   canton: { id: 0, name: 'San Carlos' },
  //   district: {
  //     id: 192,
  //     name: 'Quesada',
  //     code: 21001,
  //     area: 143.48,
  //     canton: {
  //       id: 30,
  //       name: 'San Carlos',
  //       code: 210,
  //       province: { id: 2, name: 'Alajuela' }
  //     }
  //   },
  //   visited: 'no visitado'
  // }

  const requestBody = {
    zone: { id: data.zone.id },
    district: { id: data.district.id },
    visited: data.visited === "visitado" ? true : false,
    day: data.day,
    comments: ""
  };
  await privateFetch

    .post(URL.REQUEST_ROUTE_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_save_error);
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    zone: { id: data.zone.id },
    district: { id: data.district.id },
    visited: data.visited === "visitado" ? true : false,
    day: data.day,
    comments: ""
  };
  await privateFetch
    .post(URL.REQUEST_ROUTE_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_update_error);
    });
  return responseData;
};


export const deleteRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id

  };

  await privateFetch
    .delete(URL.REQUEST_ROUTE_DELETE, { data: requestBody })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      //throw new Error(properties.com_parval_label_role_delete_error);
      console.log(error)
    });
  return responseData;
};
