import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  styled,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useApp } from "../../hooks/useApp";
import {
  getRequestCanton,
  getRequestDistrict,
  getRequestProvince,
  getRequestZone,
} from "../../services/location/LocationService";
import { getRequest } from "../../services/company/CompanyService";
import {
  getTrakingOrder,
  getLatAndLngtAddress,
} from "../../services/order/OrderRatingService";
import { formSchemaOptionsIdAndName } from "../../types/Utils";
import MapBoxTrakingOrder from "./MapBoxTrakingOrder";
import { CONSTANT } from "../../utils/Constants";
import {
  routeType,
  costByZoneType,
} from "../../pages/tabsManagement/orderTypes";
import {
  getRequestOrderDetail,
  addOrUpdOrderDetail,
  deleteOrderDocuments,
  getOrderDetail,
  deleteOrderDetail,
} from "../../services/order/ClientOrder";
import { deleteDocuments } from "../../services/blob/BlobStorage";
import { getRequestPriceZone } from "../../services/company/CompanyService";

const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
const newDateMore7 = new Date(new Date().getTime() + oneWeekInMilliseconds);

const initialDataLatAndLngt = {
  lng: 0,
  lat: 0,
};
const initialOptions = { id: 0, name: "" };

interface Location {
  id: number;
  name: string;
}

interface locationsType {
  latitude: number;
  longitude: number;
  date: string;
}

interface AddOrUpdateBiker {
  id?: number;
  name?: string;
  identification?: string;
  phone?: string;
  phone1?: string;
  reference1?: string;
  phone2?: string;
  reference2?: string;
  dueDate: Dayjs;
  provinceOrigin: Location;
  cantonOrigin: Location;
  sourceDistrict: Location;
  sourceAddress?: string;
  province: Location;
  canton: Location;
  district: Location;
  address?: string;
  price?: string;
  seriesNumbers?: { value: string }[];
  productSize?: number;
  zone?: Location;
  notes?: string;
  company: Location;
  hasRoute?: boolean;
  route: routeType;
  latitude: number;
  longitude: number;
  images?: File[];
  deliveryCode?: string;
  orderCourierNotes?: string;
  orderDetail?:any;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  routeAll: any;
  optionalRefresh?: any;
}

const AddOrUpdateBiker = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  routeAll,
  optionalRefresh,
}: DialogProps) => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
    //isAdmin,
    handleDataCompanies,
    //company,
    //routeAll
  } = useApp();
  const [companies, setCompanies] = useState<any>(null);
  const [dataZone, setDataZone] = useState<any>(null);
  const isClientRole = authInfo?.userRole?.id === 2;
  const [serialsNumber, setSerialsNumber] = useState<any>([]);

  const validation = Yup.object().shape({
    name: Yup.string().required("Campo es requerido"),
    identification: Yup.string().required("Campo es requerido"),
    phone: Yup.string().required("Campo es requerido"),
    reference1: Yup.string(),
    phone1: Yup.string().required("Campo es requerido"),
    phone2: Yup.string(),
    reference2: Yup.string(),
    dueDate: Yup.date(),
    province: Yup.object()
      .shape(formSchemaOptionsIdAndName)
      .typeError("Campo es requerido")
      .required("Campo es requerido"),
    provinceOrigin: Yup.object()
      .shape(formSchemaOptionsIdAndName)
      .typeError("Campo es requerido")
      .required("Campo es requerido"),
    canton: Yup.object()
      .shape(formSchemaOptionsIdAndName)
      .typeError("Campo es requerido")
      .required("Campo es requerido"),
    cantonOrigin: Yup.object()
      .shape(formSchemaOptionsIdAndName)
      .typeError("Campo es requerido")
      .required("Campo es requerido"),
    district: Yup.object()
      .shape(formSchemaOptionsIdAndName)
      .typeError("Campo es requerido")
      .required("Campo es requerido"),
    sourceDistrict: Yup.object()
      .shape(formSchemaOptionsIdAndName)
      .typeError("Campo es requerido")
      .required("Campo es requerido"),
    address: Yup.string().required("Campo es requerido"),
    sourceAddress: Yup.string().required("Campo es requerido"),
    seriesNumbers: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().nullable(),
      })
    ),
    productSize: Yup.number(),
    zone: Yup.object()
      .shape(formSchemaOptionsIdAndName)
      .typeError("Campo es requerido"),
    notes: Yup.string().nullable(),
    company: Yup.object()
      .shape(formSchemaOptionsIdAndName)
      .typeError("Campo es requerido"),
    hasRoute: Yup.boolean(),
    route: Yup.object().nullable(),
  });

  const defaultValues = {
    id: data ? data.id : 0,
    name: data ? data.name : "",
    identification: data ? data.identification : "",
    phone: data ? data.phone : "",
    phone1: data ? data.phone1 : "",
    reference1: data ? data.reference1 : "",
    phone2: data ? data.phone2 : "",
    reference2: data ? data.reference2 : "",
    provinceOrigin: data
      ? data.sourceDistrict.canton.province
      : { id: 0, name: "" },
    cantonOrigin: data ? data.sourceDistrict.canton : { id: 0, name: "" },
    sourceDistrict: data ? data.sourceDistrict : { id: 0, name: "" },
    sourceAddress: data ? data.sourceAddress : "",
    province: data ? data.route.district.canton.province : { id: 0, name: "" },
    canton: data ? data.route.district.canton : { id: 0, name: "" },
    district: data ? data.route.district : { id: 0, name: "" },
    address: data ? data.address : "",
    price: data ? data.amount : "₡",
    seriesNumbers: data ? data.seriesNumbers : [{ value: "" }],
    productSize: data ? data?.productSize : 0,
    zone: data ? data.route.zone : { id: 0, name: "" },
    notes: data ? data.notes : "",
    company: isClientRole
      ? authInfo?.company
      : data
        ? data.company
        : { id: 0, name: "" },
    route: data ? data.route : {},
    images: [],
    latitude: data?.latitude ?? 0,
    longitude: data?.longitude ?? 0,
    deliveryCode: data?.deliveryCode ?? 0,
    orderCourierNotes: data?.orderStatuses[3]?.description ?? "",
    deliveryCourier: data?.deliveryCourier ?? null,
    orderDetail: serialsNumber ?? null,
  };

  console.log(data)

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm<AddOrUpdateBiker>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "seriesNumbers",
  });

  const [images, setImages] = useState<any>([]);
  const [images2, setImages2] = useState<any>([]);
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [provinceData, setProvinceData] = useState<any>(null);
  const [selectedProvince, setSelectedProvince] = useState<any>(null);
  const [selectedProvinceOrigin, setSelectedProvinceOrigin] =
    useState<any>(null);
  const [cantonData, setCantonData] = useState<any>(null);
  const [selectedCanton, setSelectedCanton] = useState<any>(null);
  const [cantonDataOrigin, setCantonDataOrigin] = useState<any>(null);
  const [selectedCantonOrigin, setSelectedCantonOrigin] = useState<any>(null);
  const [districtData, setDistrictData] = useState<any>(null);
  const [districtDataOrigin, setDistrictDataOrigin] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [origin, setOrigin] = useState("");
  const [localOrderDocuments, setLocalOrderDocuments] = useState(data?.orderDocuments || []);
  /* const [origin, setOrigin] = useState(
    "San José, Escazú, Centro Corporativo Plaza Roble, Edificio Las Terrazas"
  ); */
  const [destination, setDestination] = useState("");
  const [coordinates, setCoordinates] = useState<any>(null);
  const [originCoordinates, setOriginCoordinates] = useState<any>(
    initialDataLatAndLngt
  );
  const [destinationCoordinates, setDestinationCoordinates] = useState(
    initialDataLatAndLngt
  );
  const [costByZone, setCostByZone] = useState<costByZoneType[] | null>(null);

  const [serials, setSerials] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps: Partial<any> = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
        overflowX: "auto",
      },
    },
  };
  //Origin
  const watch_provinceOrigin: Location = watch("provinceOrigin");
  const watch_cantonOrigin: Location = watch("cantonOrigin");
  const watch_sourceDistrict: Location = watch("sourceDistrict");
  const watch_sourceAddress: any = watch("sourceAddress");
  //Destination
  const watch_province: Location = watch("province");
  const watch_canton: Location = watch("canton");
  const watch_distrito: Location = watch("district");
  const watch_address: any = watch("address");
  const watch_company: any = watch("company");
  const watch_zone: any = watch("zone");

  useEffect(() => {
    reset(defaultValues);
  }, [companies]);

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData();
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      watch_province?.name !== "" &&
      watch_canton?.name !== "" &&
      watch_distrito?.name !== "" &&
      watch_address !== ""
    ) {
      setDestination(
        `${watch_address +
        ", " +
        watch_distrito.name +
        ", " +
        watch_province.name +
        ", " +
        watch_canton.name
        }`
      );
    }
  }, [watch_province, watch_canton, watch_distrito, watch_address]);

  useEffect(() => {
    if (
      watch_provinceOrigin?.name !== "" &&
      watch_cantonOrigin?.name !== "" &&
      watch_sourceDistrict?.name !== "" &&
      watch_sourceAddress !== ""
    ) {
      setOrigin(
        `${watch_sourceAddress +
        ", " +
        watch_sourceDistrict.name +
        ", " +
        watch_provinceOrigin.name +
        ", " +
        watch_cantonOrigin.name
        }`
      );
    }
    if (watch_sourceDistrict?.name) {
    }
  }, [
    watch_provinceOrigin,
    watch_cantonOrigin,
    watch_sourceDistrict,
    watch_sourceAddress,
  ]);

  useEffect(() => {
    const dataInit = async () => {
      if (selectedProvince) {
        let data = await getRequestCanton(selectedProvince);
        if (data) {
          setCantonData(data);
        }
      }
    };
    dataInit();

    // eslint-disable-next-line
  }, [selectedProvince]);

  useEffect(() => {
    const dataInit = async () => {
      if (selectedProvinceOrigin) {
        let data = await getRequestCanton(selectedProvinceOrigin);
        if (data) {
          setCantonDataOrigin(data);
        }
      }
    };
    dataInit();

    // eslint-disable-next-line
  }, [selectedProvinceOrigin]);

  useEffect(() => {
    const dataInit = async () => {
      if (selectedCanton) {
        let data = await getRequestDistrict(selectedCanton);

        if (data) {
          setDistrictData(data);
        }
      }
    };
    dataInit();

    // eslint-disable-next-line
  }, [selectedCanton]);

  useEffect(() => {
    const dataInit = async () => {
      if (selectedCantonOrigin) {
        let data = await getRequestDistrict(selectedCantonOrigin);
        if (data) {
          setDistrictDataOrigin(data);
        }
      }
    };
    dataInit();

    // eslint-disable-next-line
  }, [selectedCantonOrigin]);

  useEffect(() => {
    if (watch_distrito?.id && watch_distrito?.id !== 0) {
      let findRoute: routeType | null = null;
      findRoute = routeAll?.find(
        (item: routeType) => item.district.id === watch_distrito?.id
      );

      if (findRoute !== null) {
        setValue("route", findRoute);
        setValue("zone", findRoute?.zone);
      }
    }
  }, [watch_distrito]);

  const handleFetchData = async () => {
    //setLoading && setLoading(true);
    try {
      let province = await getRequestProvince();
      if (province) {
        setProvinceData(province);
      }
      //Obtener compañías
      let company: any = await getRequest(0, "");
      if (company) {
        setCompanies(company.content);
      }

      //Obtener zona
      let zona: any = await getRequestZone();
      if (zona) {
        setDataZone(zona);
      }

      //Obtener Coordenas de Orden
      if (data.id) {
        let dataTraking: any = await getTrakingOrder(data.id);
        setCoordinates(dataTraking.locations);
      }

      const getSerials = await getRequestOrderDetail(data.id);
      if (getSerials) {
        console.log("getSerials: ", getSerials);
        setSerials(getSerials);
      }

      //Dirección destino
      if (data.clientOrder.route.district.canton.province.id) {
        const cantons = await getRequestCanton(
          data.clientOrder.route.district.canton.province.id
        );
        setCantonData(cantons);
      }

      //Dirección origen
      if (data.clientOrder.sourceDistrict.canton.province.id) {
        const cantonsOrigin = await getRequestCanton(
          data.clientOrder.sourceDistrict.canton.province.id
        );
        setCantonDataOrigin(cantonsOrigin);
      }

      //Canton destino
      if (data.clientOrder.route.district.canton.id) {
        const districts = await getRequestDistrict(
          data.clientOrder.route.district.canton.id
        );
        setDistrictData(districts);
      }

      //Canton origen
      if (data.clientOrder.sourceDistrict.id) {
        const districtsOrigin = await getRequestDistrict(
          data.clientOrder.sourceDistrict.id
        );
        setDistrictDataOrigin(districtsOrigin);
      }

      //setLoading && setLoading(false);
    } catch (error: any) {
      //setLoading && setLoading(false);
      //setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchSerialData = async () => {
    //setLoading && setLoading(true);
    try {
      let info = await getOrderDetail(data?.id);
      if (info) {
        setSerialsNumber(info)

      }

      //setLoading && setLoading(false);
    } catch (error: any) {
      //setLoading && setLoading(false);
      //setErrorMsg && setErrorMsg(error.message);
    }
  };

  useEffect(() => {
    const dataInit = async () => {
      //await handleFetchData(0, "");
      if (data?.id) {
        handleFetchSerialData();

      }
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  console.log(serialsNumber)

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((prev: any) => [
          ...prev,
          { name: file.name, url: reader.result },
        ]);
        setImages2((prev: any) => [...prev, file]);
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (images2.length > 0) {
      setValue("images", images2);
    }
  }, [images2]);

  const handleImageRemove = (index: any) => {
    console.log(index)
    setImages((prev: any) => prev.filter((_: any, i: any) => i !== index));
  };
  console.log(data)

  const handleImageClick = (url: string) => {
    setSelectedImage(url);
    setShowImageModal(true);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    setValue("productSize", fields.length + serialsNumber?.length);
  }, [fields, setValue,serialsNumber]);

  useEffect(() => {
    setValue("orderDetail", serialsNumber);
  }, [serialsNumber]);

  const handleProvinceChange = (event: any) => {
    setValue("canton", initialOptions);
    setValue("district", initialOptions);
    if (event?.id) {
      const selectedProvinceID = event?.id;
      const selectedProvince = provinceData.find(
        (province: any) => province.id === selectedProvinceID
      )?.id;
      setSelectedProvince(selectedProvince ? selectedProvince : "");
      setCantonData(null);
      setDistrictData(null);
    }
  };

  const handleProvinceChangeOrigin = (event: any) => {
    setValue("cantonOrigin", initialOptions);
    setValue("sourceDistrict", initialOptions);
    setCantonDataOrigin(null);
    setDistrictDataOrigin(null);
    if (event?.id) {
      const selectedProvinceIDOrigin = event?.id;
      const selectedProvinceOrigin = provinceData.find(
        (province: any) => province.id === selectedProvinceIDOrigin
      )?.id;
      setSelectedProvinceOrigin(
        selectedProvinceOrigin ? selectedProvinceOrigin : ""
      );
    }
  };

  const handleCantonChange = (event: any) => {
    setValue("district", initialOptions);
    setDistrictData(null);
    if (event?.id) {
      const selectedCantonID = event.id;
      const selectedCanton = cantonData.find(
        (canton: any) => canton.id === selectedCantonID
      ).id;
      setSelectedCanton(selectedCanton ? selectedCanton : "");
    }
  };

  const handleCantonChangeOrigin = (event: any) => {
    setValue("sourceDistrict", initialOptions);
    setDistrictDataOrigin(null);
    if (event?.id) {
      const selectedCantonIDOrigin = event.id;
      const selectedCantonOrigin = cantonDataOrigin.find(
        (canton: any) => canton.id === selectedCantonIDOrigin
      )?.id;
      setSelectedCantonOrigin(selectedCantonOrigin ? selectedCantonOrigin : "");
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleLatAndLngtAddress = async (address: string) => {
    try {
      const data = await getLatAndLngtAddress(address, CONSTANT.MAPBOX_TOKEN);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let dataO = null;
    const getData = async () => {
      dataO = await handleLatAndLngtAddress(origin);
      if (dataO.features[0].geometry.coordinates.length > 0) {
        setOriginCoordinates({
          lng: dataO.features[0].geometry.coordinates[0],
          lat: dataO.features[0].geometry.coordinates[1],
        });
      }
    };
    if (origin !== "") {
      getData();
    }
  }, [origin]);

  useEffect(() => {
    let dataD = null;
    const getData = async () => {
      dataD = await handleLatAndLngtAddress(destination);
      if (dataD?.features[0].geometry.coordinates.length > 0) {
        setDestinationCoordinates({
          lng: dataD.features[0].geometry.coordinates[0],
          lat: dataD.features[0].geometry.coordinates[1],
        });
        setValue("latitude", dataD.features[0].geometry.coordinates[1]);
        setValue("longitude", dataD.features[0].geometry.coordinates[0]);
      }
    };
    if (destination !== "") {
      getData();
    }
  }, [destination]);

  const handleFetchCoordinates = async () => {
    let dataTraking: any = await getTrakingOrder(data.id);
    setCoordinates(dataTraking.locations);
  };

  setTimeout(() => {
    if (modalType === "onTheWay") {
      handleFetchCoordinates();
    }
  }, 60000);

  useEffect(() => {
    // Actualiza el estado local cuando cambie la prop `data.orderDocuments`
    setLocalOrderDocuments(data?.orderDocuments || []);
  }, [data?.orderDocuments]);

  const handleDocumentDelete = async (item: any) => {
    setLoading && setLoading(true);
    try {
      const deleteOrderDocument: any = await deleteOrderDocuments(item.id);
      if (deleteOrderDocument.deleted) {
        const deleteDoc: any = await deleteDocuments(item.fileName);
        if (!deleteDoc) {
          setLoading && setLoading(false);
          setErrorMsg && setErrorMsg("Error al borrar imagen");
          return;
        }

        // Eliminar la imagen del estado local
        const updatedOrderDocuments = localOrderDocuments.filter(
          (doc: any) => doc.id !== item.id
        );
        setLocalOrderDocuments(updatedOrderDocuments);  // Actualizar el estado local
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg("Registro borrado correctamente");
      optionalRefresh(0, "");
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  // const newSerialsNumber = serialsNumber.filter((_:any, i:any) => i !== index);
  //                   setSerialsNumber(newSerialsNumber); // Actualiza el estado al eliminar

  const handleDeleteSerial = async (item: any) => {
    console.log(item)
    try {
      const deleteOrderDocument: any = await deleteOrderDetail(item.id);
      if (deleteOrderDocument.deleted) {

      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
    }
  };


  const handleFetchCostByZone = async (id: number) => {
    try {
      const getCost: any = await getRequestPriceZone(0, id);
      if (getCost) {
        setCostByZone(getCost.content);
        return getCost.content;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getCostByZone = async () => {
      let cost = await handleFetchCostByZone(watch_company.id);
    };
    if (watch_company.id !== 0) {
      getCostByZone();
    }
  }, [watch_company]);

  useEffect(() => {
    const getCost = async () => {
      let filterCost: any = costByZone?.find(
        (c: costByZoneType) => c.zone.id === watch_zone.id
      );

      setValue("price", filterCost?.clientAmount);
    };

    if (watch_zone.id !== 0) {
      getCost();
    }
  }, [watch_zone, costByZone]);

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create"
          ? "Agregar pedidos"
          : modalType === "update"
            ? "Actualizar pedidos"
            : "Datos de la entrega"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="xl" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Typography
                id="alert-dialog-title"
                variant="h6"
                component="div"
                sx={{ mb: 1, fontSize: "1.15rem", color: "#3E627C" }}
              >
                Datos cliente
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Nombre del cliente"}
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={
                      modalType === "finish" || modalType === "onTheWay"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name={"identification"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Identificación del cliente"}
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("identification")}
                    error={
                      errors.identification && Boolean(errors.identification)
                    }
                    helperText={
                      errors.identification && errors.identification.message
                    }
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={
                      modalType === "finish" || modalType === "onTheWay"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="company"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={
                      companies !== null ? companies : [{ id: 0, name: "" }]
                    }
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    disabled={
                      modalType === "finish" || modalType === "onTheWay" || authInfo?.userRole?.id === 2
                    }
                    sx={{ pb: 0 }}
                    renderInput={(params: any) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Empresa"
                          variant="standard"
                          error={!!errors.company}
                          helperText={errors.company && errors.company.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name={"phone"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Teléfono 1"}
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("phone")}
                    error={errors.phone && Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={
                      modalType === "finish" || modalType === "onTheWay"
                    }
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <Controller
                name={"dueDate"}
                control={control}
                render={({ field, ...props }) => (
                  <FormControl
                    fullWidth
                    sx={{ marginTop: "7px", paddingTop: "8px" }}
                  >
                    <InputLabel
                      shrink={true}
                      error={errors.dueDate && Boolean(errors.dueDate)}
                    >
                      Día de máxima entrega
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={dayjs(field.value)}
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        //minDate={dayjs(new Date())}
                        maxDate={dayjs(newDateMore7)}
                        slotProps={{ textField: { variant: "standard" } }}
                        disabled={modalType === "finish" || authInfo?.userRole?.id === 2}
                      />
                    </LocalizationProvider>
                  </FormControl>
                )}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Typography
                id="alert-dialog-title"
                variant="h6"
                component="div"
                sx={{ mb: 1, fontSize: "1.15rem", color: "#3E627C" }}
              >
                Números de teléfono opcionales
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={"reference1"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Referencia 1"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("reference1")}
                    error={errors.reference1 && Boolean(errors.reference1)}
                    helperText={errors.reference1 && errors.reference1.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={
                      modalType === "finish" || modalType === "onTheWay"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={"phone1"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Teléfono 2"}
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("phone1")}
                    error={errors.phone1 && Boolean(errors.phone1)}
                    helperText={errors.phone1 && errors.phone1.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={
                      modalType === "finish" || modalType === "onTheWay"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={"reference2"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Referencia 2"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("reference2")}
                    error={errors.reference2 && Boolean(errors.reference2)}
                    helperText={errors.reference2 && errors.reference2.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={
                      modalType === "finish" || modalType === "onTheWay"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={"phone2"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Teléfono 3"}
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("phone2")}
                    error={errors.phone2 && Boolean(errors.phone2)}
                    helperText={errors.phone2 && errors.phone2.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={
                      modalType === "finish" || modalType === "onTheWay"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="div"
                sx={{ mb: 1, fontSize: "1.15rem", color: "#3E627C" }}
              >
                Dirección de origen
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="provinceOrigin"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(e, newValue: any) => {
                      onChange(newValue);
                      handleProvinceChangeOrigin(newValue);
                    }}
                    options={
                      provinceData !== null
                        ? provinceData
                        : [{ id: 0, name: "" }]
                    }
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    sx={{ pb: 0 }}
                    disabled={
                      modalType === "finish" || modalType === "onTheWay"
                    }
                    renderInput={(params: any) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Provincia"
                          variant="standard"
                          error={!!errors.provinceOrigin}
                          helperText={
                            errors.provinceOrigin &&
                            errors.provinceOrigin.message
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="cantonOrigin"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(e, newValue: any) => {
                      onChange(newValue);
                      handleCantonChangeOrigin(newValue);
                    }}
                    options={
                      cantonDataOrigin !== null
                        ? cantonDataOrigin
                        : [{ id: 0, name: "" }]
                    }
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    disabled={cantonDataOrigin === null}
                    //@ts-ignore
                    value={value}
                    sx={{ pb: 0 }}
                    renderInput={(params: any) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Cantón"
                          variant="standard"
                          error={!!errors.cantonOrigin}
                          helperText={
                            errors.cantonOrigin && errors.cantonOrigin.message
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="sourceDistrict"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(e, newValue: any) => {
                      onChange(newValue);
                      //handleCantonChangeOrigin(newValue);
                    }}
                    options={
                      districtDataOrigin !== null
                        ? districtDataOrigin
                        : [{ id: 0, name: "" }]
                    }
                    getOptionLabel={(option: any) => option?.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    disabled={districtDataOrigin === null}
                    //@ts-ignore
                    value={value}
                    sx={{ pb: 0 }}
                    renderInput={(params: any) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Distrito"
                          variant="standard"
                          error={!!errors.sourceDistrict}
                          helperText={
                            errors.sourceDistrict &&
                            errors.sourceDistrict.message
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name={"sourceAddress"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Dirección exacta"}
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("sourceAddress")}
                    error={
                      errors.sourceAddress && Boolean(errors.sourceAddress)
                    }
                    helperText={
                      errors.sourceAddress && errors.sourceAddress.message
                    }
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={
                      modalType === "finish" || modalType === "onTheWay"
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="div"
                sx={{ mb: 1, fontSize: "1.15rem", color: "#3E627C" }}
              >
                Dirección de destino
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="province"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(e, newValue: any) => {
                      onChange(newValue);
                      handleProvinceChange(newValue);
                    }}
                    options={
                      provinceData !== null
                        ? provinceData
                        : [{ id: 0, name: "" }]
                    }
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    disabled={
                      modalType === "finish" || modalType === "onTheWay"
                    }
                    //@ts-ignore
                    value={value}
                    sx={{ pb: 0 }}
                    renderInput={(params: any) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Provincia"
                          variant="standard"
                          error={!!errors.province}
                          helperText={
                            errors.province && errors.province.message
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="canton"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(e, newValue: any) => {
                      onChange(newValue);
                      handleCantonChange(newValue);
                    }}
                    disabled={cantonData === null}
                    options={
                      cantonData !== null ? cantonData : [{ id: 0, name: "" }]
                    }
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    sx={{ pb: 0 }}
                    renderInput={(params: any) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Cantón"
                          variant="standard"
                          error={!!errors.canton}
                          helperText={errors.canton && errors.canton.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="district"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(e, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={
                      districtData !== null
                        ? districtData
                        : [{ id: 0, name: "" }]
                    }
                    disabled={districtData === null}
                    getOptionLabel={(option: any) => option?.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    sx={{ pb: 0 }}
                    renderInput={(params: any) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Distrito"
                          variant="standard"
                          error={!!errors.district}
                          helperText={
                            errors.district && errors.district.message
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name={"address"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Dirección exacta"}
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("address")}
                    error={errors.address && Boolean(errors.address)}
                    helperText={errors.address && errors.address.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={
                      modalType === "finish" || modalType === "onTheWay"
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name={"price"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Monto del paquete"}
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("price")}
                    error={errors.price && Boolean(errors.price)}
                    helperText={errors.price && errors.price.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={true}
                  />
                )}
              />
            </Grid>
            {coordinates !== null && coordinates.length > 0 && (
              <Grid
                item
                xs={12}
                sx={{
                  height: "400px",
                }}
              >
                <MapBoxTrakingOrder
                  locations={coordinates}
                  origin={origin}
                  destination={destination}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="div"
                sx={{ mb: 1, fontSize: "1.15rem", color: "#3E627C" }}
              >
                Series
              </Typography>
            </Grid>

            <Grid item xs={4} sx={{ display: "flex", alignItems: "flex-end" }}>
              <Button
                startIcon={<AddIcon />}
                onClick={handleOpenDialog}
                // disabled={
                //   fields.length >= 10 ||
                //   modalType === "onTheWay" ||
                //   modalType === "finish"
                // }
                fullWidth
                sx={{ height: "32px", justifyContent: "flex-start" }}
              >
                Ver No. Serie
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name={"productSize"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Cantidad de productos"}
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("productSize")}
                    error={errors.productSize && Boolean(errors.productSize)}
                    helperText={
                      errors.productSize && errors.productSize.message
                    }
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    disabled={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="zone"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(e, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={dataZone !== null ? dataZone : [initialOptions]}
                    disabled
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    sx={{ pb: 0 }}
                    renderInput={(params: any) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Zona"
                          variant="standard"
                          error={!!errors.zone}
                          helperText={errors.zone && errors.zone.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="div"
                sx={{ mb: 1, fontSize: "1.15rem", color: "#3E627C" }}
              >
                Notas
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"notes"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Notas"}
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("notes")}
                    error={errors.notes && Boolean(errors.notes)}
                    helperText={errors.notes && errors.notes.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          color="primary"
                          component="label"
                          disabled={
                            modalType === "finish" || modalType === "onTheWay"
                          }
                        >
                          <PhotoCamera />
                          <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={handleImageUpload}
                          />
                        </IconButton>
                      ),
                    }}
                    disabled={
                      modalType === "finish" || modalType === "onTheWay"
                    }
                  />
                )}
              />
            </Grid>
            {images.map((image: any, index: number) => (
              <Grid item xs={12} key={index}>
                <Box display="flex" alignItems="center">
                  <img
                    src={image.url}
                    alt={image.name}
                    width="50"
                    height="50"
                    onClick={() => handleImageClick(image.url)}
                    style={{ cursor: "pointer" }}
                  />
                  <Box ml={2} flexGrow={1}>
                    {image.name}
                  </Box>
                  <IconButton
                    color="error"
                    onClick={() => handleImageRemove(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
            ))}
            {localOrderDocuments.map((item: any, index: number) => (
              <Grid item xs={12} key={index}>
                <Box display="flex" alignItems="center">
                  <img
                    src={item.url}
                    alt={item.fileName}
                    width="50"
                    height="50"
                    onClick={() => handleImageClick(item.url)}
                    style={{ cursor: "pointer" }}
                  />
                  <Box ml={2} flexGrow={1}>
                    {item.fileName}
                  </Box>
                  <IconButton
                    color="error"
                    disabled={
                      modalType === "finish" || modalType === "onTheWay"
                    }
                    onClick={() => handleDocumentDelete(item)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
            ))}

            {modalType === "finish" && (
              <>
                <Grid item xs={12}>
                  <Controller
                    name={"orderCourierNotes"}
                    control={control}
                    render={({ field: { value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label={`Observaciones del mensajero (${data.deliveryCourier.firstName +
                          " " +
                          data.deliveryCourier.lastName
                          })`}
                        type="text"
                        variant="standard"
                        value={value}
                        sx={{ "& input": { pl: "0!important" } }}
                        {...register("orderCourierNotes")}
                        error={
                          errors.orderCourierNotes &&
                          Boolean(errors.orderCourierNotes)
                        }
                        helperText={
                          errors.orderCourierNotes &&
                          errors.orderCourierNotes.message
                        }
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        disabled={
                          modalType === "finish" || modalType === "onTheWay"
                        }
                      />
                    )}
                  />
                </Grid>
                {/* {data?.orderStatuses[3]?.url && (
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <img
                        src={data?.orderStatuses[3]?.url}
                        alt="Respaldo mensajero"
                        width="50"
                        height="50"
                        onClick={() =>
                          handleImageClick(data?.orderStatuses[3]?.url)
                        }
                        style={{ cursor: "pointer" }}
                      />
                      <Box ml={2} flexGrow={1}>
                        Evidencia mensajero
                      </Box>
                    </Box>
                  </Grid>
                )} */}

                {data?.orderStatuses[3]?.urls?.map((item: any, index: number) => (
                  <Grid item xs={12} key={index}>
                    <Box display="flex" alignItems="center">
                      <img
                        src={item}
                        alt={"Respaldo mensajero"}
                        width="50"
                        height="50"
                        onClick={() => handleImageClick(item)}
                        style={{ cursor: "pointer" }}
                      />
                      <Box ml={2} flexGrow={1}>
                        {"Respaldo mensajero"}
                      </Box>
                      <IconButton
                        color="error"
                        disabled={
                          modalType === "finish" || modalType === "onTheWay"
                        }
                        onClick={() => handleDocumentDelete(item)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </form>
      </DialogContent>
      {/* Modal para mostrar imagen en grande  */}
      <Dialog open={showImageModal} onClose={handleCloseImageModal}>
        <DialogTitle>Imagen Ampliada</DialogTitle>
        <DialogContent>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Ampliación de imagen"
              style={{ maxWidth: "100%" }}
            />
          )}
        </DialogContent>
      </Dialog>
      {/* Fin modal para mostrar imagen en grande  */}
      {/* Modal para agregar número de serie */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Agregar número de serie</DialogTitle>
        <DialogContent>
          {serialsNumber?.map((field: any, index: any) => (
            <Grid item xs={12} key={field.id} sx={{ mt: 2 }}>
              <Box display="flex" alignItems="flex-end">
                <TextField
                  fullWidth
                  size="small"
                  label={`No. Serie Asignada ${index + 1}`}
                  type="text"
                  variant="standard"
                  value={field.serieNumber} // Asigna el valor inicial
                  onChange={(e) => {
                    const newSerialsNumber = [...serialsNumber];
                    newSerialsNumber[index].serieNumber = e.target.value;
                    setSerialsNumber(newSerialsNumber); // Asegúrate de tener un setSerialsNumber para actualizar el estado
                  }}
                  disabled={modalType === "finish" || modalType === "onTheWay"}
                />
                <IconButton
                  color="error"
                  onClick={() => {
                    handleDeleteSerial(field);
                    const newSerialsNumber = serialsNumber.filter((_:any, i:any) => i !== index);
                    setSerialsNumber(newSerialsNumber); // Actualiza el estado al eliminar
                  }}
                  sx={{ ml: 1, mb: 1 }}
                  disabled={modalType === "finish" || modalType === "onTheWay"}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>
          ))}
          {fields.map((field, index) => (
            <Grid item xs={12} key={field.id} sx={{ mt: 2 }}>
              <Box display="flex" alignItems="flex-end">
                <Controller
                  name={`seriesNumbers.${index}.value`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={`No. Serie Asignada ${serialsNumber?.length + index + 1}`}
                      type="text"
                      variant="standard"
                      {...field}
                      error={
                        errors.seriesNumbers?.[index]?.value &&
                        Boolean(errors.seriesNumbers[index])
                      }
                      helperText={errors.seriesNumbers?.[index]?.value?.message}
                      disabled={
                        modalType === "finish" || modalType === "onTheWay"
                      }
                    />
                  )}
                />
                <IconButton
                  color="error"
                  onClick={() => remove(index)}
                  sx={{ ml: 1, mb: 1 }}
                  disabled={modalType === "finish" || modalType === "onTheWay"}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>
          ))}
          {fields.length > 0 && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton
                  color="primary"
                  onClick={() => append({ value: "" })}
                  sx={{ mt: -2 }}
                  disabled={
                    fields.length + serialsNumber?.length >= 10 ||
                    modalType === "onTheWay" ||
                    modalType === "finish"
                  }
                >
                  <AddIcon
                    sx={{
                      fontSize: 17,
                      strokeWidth: 2,
                      stroke: "currentColor",
                    }}
                  />
                </IconButton>
              </Box>
            </Grid>
          )}
          {fields.length === 0 && (
            <Grid item xs={12}>
              <Button
                startIcon={<AddIcon />}
                onClick={() => append({ value: "" })}
                disabled={
                  fields.length + serialsNumber?.length >= 10  ||
                  modalType === "onTheWay" ||
                  modalType === "finish"
                }
              >
                Agregar No. Serie
              </Button>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Fin para modal de agregar número de serie */}
      <DialogActions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {"Aceptar"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {"Cancelar"}
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default AddOrUpdateBiker;
