// src/components/TabCard1Form.tsx
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from "@mui/material";
import FancyTablePagination from "../../components/utils/FancyTablePagination";
import { useForm } from "react-hook-form";
import {
  PlusIcon,
  PenIcon,
  TrashIcon,
} from "../../components/svgicons/SvgIcons";
import DownloadIcon from "@mui/icons-material/Download";
import MopedIcon from "@mui/icons-material/Moped";
import FancyTooltip from "../../components/utils/FancyTooltip";
import DeleteModal from "../../components/utils/DeleteModal";
import { useApp } from "../../hooks/useApp";
import ResourceAccess from "../../components/security/ResourceAccess";
import AddOrUpdateBiker from "../../components/utils/AddOrUpdateBiker";
import AssignDriverModal from "../../components/utils/AssigneDriverModal";
import ChangeStatusModal from "../../components/utils/ChangeStatusModal";
import { properties } from "../../utils/Properties_es";
import { itemOrderType, orderStatusAllType } from "./orderTypes";
import { clientOrderByStatusType } from "../../types/Context";
import { initialStateContent } from "./initialStateOrderByStatus";
import { uploadExcelFile } from "../../services/report/ReportServices";
import FancyDialogActions from "../../components/utils/FancyDialogActions";

interface TabCard1FormProps {
  data: any;
  handleAdd: any;
  handleUpdate: any;
  handleAssignedDriver: any;
  handleDelete: any;
  updatePendingCount: number;
  allOrderStatus: orderStatusAllType[] | null;
  handleAddOrUpdateStatusOrder: any;
  images: any;
  setImages: any;
}

const TabCardPendingForm: React.FC<TabCard1FormProps> = ({
  data,
  handleAdd,
  handleUpdate,
  handleAssignedDriver,
  handleDelete,
  updatePendingCount,
  allOrderStatus,
  handleAddOrUpdateStatusOrder,
  images,
  setImages,
}) => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    historyGroupByPackStatus,
    handleHistoryByPackStatus,
    handleFetchHistoryGroupByPackStatus,
    routeAll,
    countTabs,
    setCountTabs,
  } = useApp();

  const theme = useTheme();

  const [preFilter, setPreFilter] = useState<string>("");
  const [page, setPage] = useState(0);
  const [orderPennding, setOrderPennding] =
    useState<clientOrderByStatusType>(initialStateContent);
  const { handleSubmit } = useForm();

  useEffect(() => {
    if (historyGroupByPackStatus !== null) {
      setOrderPennding(historyGroupByPackStatus.pending);
    }
  }, [historyGroupByPackStatus]);

  const fetchData = async () => {
    await handleFetchHistoryGroupByPackStatus(
      authInfo?.company?.id !== 10 ? authInfo?.company?.id : 0
    );
    //await handleFetchOrderStatusAll();
  };

  useEffect(() => {
    const getData = async () => {
      await handleFetchData(0, preFilter);
    };

    if (updatePendingCount !== 0) {
      getData();
    }
    // eslint-disable-next-line
  }, [updatePendingCount]);

  const handleFetchData = async (page: number, filter: string) => {
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(page);

      let orderData: any = await handleHistoryByPackStatus(
        1,
        page,
        filter,
        authInfo?.company?.id !== 10 ? authInfo?.company?.id : 0
      );
      if (orderData) {
        console.log(orderData)
        setOrderPennding(orderData);
        let updateCountTabs = { ...countTabs };
        updateCountTabs.pending = orderData.totalElements;
        setCountTabs(updateCountTabs);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const onSubmit = async (formData: any) => {
    console.log("pendingTab", formData);
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(formData);
        break;
      case "update":
        await handleUpdate(1, formData);
        break;
      case "assigned":
        await handleAssignedDriver(1, formData);
        break;
      case "delete":
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  const handleOpenModal = (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    const id = event.currentTarget.getAttribute("data-id");
    let object = null;
    if (
      modalAction === "update" ||
      modalAction === "assigned" ||
      modalAction === "change" ||
      modalAction === "delete"
    ) {
      object = orderPennding?.content.find(
        (p: itemOrderType) => p.id === parseInt(id)
      );
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    const customPage = newPage - 1;
    setPage(customPage);
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading && setLoading(true);
    const file = event.target.files?.[0];

    if (file) {
      try {
        await uploadExcelFile({ excelFile: file });
        setSuccessMsg && setSuccessMsg("Proceso ejecutado correctamente");
        //await handleFetchData(0, preFilter);
        await fetchData();
      } catch (error) {
        setErrorMsg && setErrorMsg("Error a la hora de ejecutar la acción");
      } finally {
        setLoading && setLoading(false);
        event.target.value = "";
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 6,
          pb: 8,
        }}
      >
        <Stack direction="row" alignItems="center" gap={8}>
          <ResourceAccess isCode={true} pathOrCode={"MANAGEMENT:WRITE"}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              data-name="create"
            >
              {"Agregar pedidos"}
              <PlusIcon sx={{ ml: 1 }} />
            </Button>
          </ResourceAccess>
          {authInfo?.userRole?.id === 1 && (
            <ResourceAccess isCode={true} pathOrCode={"MANAGEMENT:WRITE"}>
              <input
                type="file"
                accept=".xlsx, .xls" // Acepta archivos de Excel
                onChange={handleFileChange}
                style={{ display: "none" }} // Oculta el input
                id="upload-excel-input"
              />
              <label htmlFor="upload-excel-input">
                <Button variant="contained" color="primary" component="span">
                  Carga masiva de pedidos
                  <DownloadIcon sx={{ ml: 1 }} />
                </Button>
              </label>
            </ResourceAccess>
          )}
        </Stack>
        <Stack direction="row" alignItems="center">
          <TextField
            placeholder="Filtro de búsqueda"
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                borderRadius: "0.2rem 0 0 0.2rem",
              },
              "& fieldset": {
                borderRightWidth: "0",
              },
            }}
            value={preFilter}
            onChange={(e: any) => {
              setPreFilter(e.target.value);
              if (e.target.value === "") {
                console.log("ingresa aquí en buscar");
                handleFetchData(0, "");
              }
            }}
            onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={handleApplyFilter}
            sx={{
              borderRadius: "0 0.2rem 0.2rem 0!important",
            }}
          >
            Buscar
          </Button>
        </Stack>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{ backgroundColor: "red", color: "white !important" }}
              >
                {"Identificador"}
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "red", color: "white !important" }}
              >
                {"Empresa"}
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "red", color: "white !important" }}
              >
                {"Cliente"}
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "red", color: "white !important" }}
              >
                {"Tel 1"}
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "red", color: "white !important" }}
              >
                {"Tel 2"}
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "red", color: "white !important" }}
              >
                {"Zona"}
              </TableCell>
              {/*<TableCell
                align="center"
                sx={{ backgroundColor: "red", color: "white !important" }}
              >
                {"Cantidad producto"}
              </TableCell>
               <TableCell
                align="center"
                sx={{ backgroundColor: "red", color: "white !important" }}
              >
                {"Monto cliente"}
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "red", color: "white !important" }}
              >
                {"Monto mensajero"}
              </TableCell> */}
              <TableCell
                align="center"
                sx={{ backgroundColor: "red", color: "white !important" }}
              >
                {"Acciones"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderPennding !== null && orderPennding.content.length > 0 ? (
              orderPennding?.content.map((r: itemOrderType) => (
                <TableRow key={r.id}>
                  <TableCell align="center" component="th" scope="row">
                    {r.id}
                  </TableCell>
                  <TableCell align="center">{r.company.name}</TableCell>
                  <TableCell align="center">{r.name}</TableCell>
                  <TableCell align="center">{r.phone}</TableCell>
                  <TableCell align="center">{r.phone1}</TableCell>
                  <TableCell align="center">{r.route.zone.name}</TableCell>
                  {/* <TableCell align="center">5</TableCell>
                  <TableCell align="center">{r.amount}</TableCell>
                  <TableCell align="center">
                    {r.deliveryCourierAmount}
                  </TableCell> */}
                  <TableCell align="center">
                    <ResourceAccess
                      isCode={true}
                      pathOrCode={"MANAGEMENT:WRITE"}
                    >
                      <FancyTooltip title={"Editar"} placement="top">
                        <IconButton
                          aria-label="edit"
                          component="label"
                          color="primary"
                          onClick={handleOpenModal}
                          data-name="update"
                          data-id={r.id}
                        >
                          <PenIcon />
                        </IconButton>
                      </FancyTooltip>
                      <ResourceAccess
                        isCode={true}
                        pathOrCode={"MANAGEMENTEDIT:WRITE"}
                      >
                        <FancyTooltip
                          title={"Asignar Mensajero"}
                          placement="top"
                        >
                          <IconButton
                            aria-label="assigned"
                            component="label"
                            color="secondary"
                            onClick={handleOpenModal}
                            data-name="assigned"
                            data-id={r.id}
                          >
                            <MopedIcon sx={{ fontSize: "1.5rem" }} />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                      <FancyTooltip title={"Eliminar"} placement="top">
                        <IconButton
                          aria-label="trash"
                          component="label"
                          color="error"
                          sx={{
                            "&:hover": {
                              color: theme.palette.error.dark,
                            },
                          }}
                          data-id={r.id}
                          onClick={handleOpenModal}
                          data-name="delete"
                        >
                          <TrashIcon />
                        </IconButton>
                      </FancyTooltip>
                    </ResourceAccess>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  {properties.com_parval_label_table_empty}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <FancyTablePagination
        count={
          orderPennding?.content?.length > 0
            ? orderPennding?.content?.length
            : 0
        }
        rowsPerPage={orderPennding?.size}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalElements={orderPennding?.totalElements}
        totalPages={orderPennding?.totalPages}
      />
      {(modalData?.modalType === "update" ||
        modalData?.modalType === "create") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
          maxWidth="lg"
        >
          <AddOrUpdateBiker
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            routeAll={routeAll}
            optionalRefresh={handleFetchData}
          />
        </Dialog>
      )}
      {modalData?.modalType === "assigned" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
          maxWidth="lg"
        >
          <AssignDriverModal
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === "change" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <ChangeStatusModal
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            allOrderStatus={allOrderStatus}
            handleAddOrUpdateStatusOrder={handleAddOrUpdateStatusOrder}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            textChildren={modalData?.modalObject?.nombre}
            actionButton={
              <>
                <FancyDialogActions
                  handleAccept={handleSubmit(onSubmit)}
                  cancelModal={handleCancelModal}
                  textAcceptButton={"Eliminar"}
                />
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default TabCardPendingForm;
