import { Stack, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const Content = styled(Stack)(({ theme }) => ({
    minHeight: "100vh",
    padding: theme.spacing(0, 2),
}));

export const LinkTo = styled(Link)(({ theme }) => ({
    color: theme.palette.text.secondary,
    textDecoration: "none",
}));

export const Span = styled("span")(({ theme }) => ({
    color: "#00aae4",
}));