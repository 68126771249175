import { URL } from "../utils/UrlContants";
import { publicFetch } from "../utils/CustomFetch";
import { CONSTANT } from "../utils/UrlContants";
import { UrlBase } from "../utils/Urls";
import { loginTypes } from "../types/Login";

export const SVloginCodeRequest = async (data:{
  username: string,
  password: string
}) => {
  let responseData = {};
  const requestBody = {
    userName : data.username,
    password: data.password
  };
  await publicFetch
    .post(URL.LOGIN_TWOFACTORCODE_REQUEST, JSON.stringify(requestBody), {
      headers: { ...CONSTANT.HEADER_TYPE },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      if (error.response.data.code === "001") {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.response.data.message);
      }      
    });

  return responseData;
};

export const SVloginRequest = async (data:loginTypes) => {
  let responseData = {};
  const requestBody = {    
    userName: data.username,
    password: data.password,
    code: data.code ? data.code : "",
    userPortal: ""
  }; 
  
  await publicFetch
    .post(URL.LOGIN_REQUEST, JSON.stringify(requestBody), {
      headers: { ...CONSTANT.HEADER_TYPE },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      console.log(error.response.data.status);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 500
      ) {
        //throw new Error(error.response.data.status);
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.response.data.message);
      }      
    });

  return responseData;
};

export const SVRecoveryPassRequest = async (data:any) => {
  let responseData = {};
  const requestBody = {
    email: data.username,
  };  
  await publicFetch
    .post(URL.LOGIN_RECOVERY_PASS_REQUEST, JSON.stringify(requestBody), {
      headers: { ...CONSTANT.HEADER_TYPE },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error envíando mail");
    });

  return responseData;
};

export const SVChangePassRequest = async (data:any) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    password: data.password,
  };
  await publicFetch
    .put(URL.LOGIN_CHANGE_PASS_REQUEST, JSON.stringify(requestBody), {
      headers: { ...CONSTANT.HEADER_TYPE },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });

  return responseData;
};

export const SVActiveUserRequest = async (email:string, code:string) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["username", email],
    ["code", code],
  ]);
  await publicFetch
    .get(URL.LOGIN_ACTIVE_USER_REQUEST, { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });

  return responseData;
};

export const SVSessionValidateRequest = async (email:string, sessionCode:string, id: string) => {
  let responseData = {};
  const requestBody = {
    userName: email,
    sessionCode: sessionCode,
    id: id
  };
  await publicFetch
    .post(URL.SESSIONVALIDATE_REQUEST, JSON.stringify(requestBody), {
      headers: { ...CONSTANT.HEADER_TYPE },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 500)
      ) {
        throw new Error(error.message);
      }
      throw new Error(error.message);
    });

  return responseData;
};

export const SVCancelSubscriptionRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
      email: data.username,
  };
  await publicFetch
      .post(URL.CANCEL_SUBSCRIPTION_REQUEST, JSON.stringify(requestBody), {
          headers: { ...CONSTANT.HEADER_TYPE },
      })
      .then(async (response: any) => {
          responseData = await response.data;
      })
      .catch((error: any) => {
          let responseError = "Error cancelando la suscripción"; 
          if (error.response && error.response.status === 404 && error.response.data && error.response.data.info) {
              responseError = error.response.data.info;
          }
          throw new Error(responseError);
      });

  return responseData;
};


