import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  styled,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateRole {
  id?: number;
  adminLogin: boolean;
  clientLogin: boolean;
  description: string;
  roleName: string;
  notify?: {
    id?: boolean;
    name?: string;
  };
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateRoleModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  //Validación de datos
  const validation = Yup.object().shape({
    adminLogin: Yup.boolean(),
    clientLogin: Yup.boolean(),
    description: Yup.string().required("Campo requerido"),
    roleName: Yup.string().required("Campo requerido"),
    notify: Yup.object().shape({
      id: Yup.boolean().required("Campo requerido"),
    }),
  });

  const defaultValues = {
    id: data ? data.id : 0,
    adminLogin: data ? data.adminLogin : false,
    clientLogin: data ? data.clientLogin : false,
    description: data ? data.description : "",
    roleName: data ? data.roleName : "",
    companyId: data ? data.companyId : 0,
    notify:
      data && data.notify
        ? { id: true, name: "Si" }
        : { id: false, name: "No" },
  };

  console.log("defaultValues", defaultValues);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateRole>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update" ? "Actualizar perfil" : "Añadir perfil"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"roleName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Nombre"}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("roleName")}
                    error={errors.roleName && Boolean(errors.roleName)}
                    helperText={errors.roleName && errors.roleName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={"Descripción"}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="description"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("description")}
                    error={errors.description && Boolean(errors.description)}
                    helperText={
                      errors.description && errors.description.message
                    }
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Controller
                  control={control}
                  name="notify"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                      <Autocomplete
                          onChange={(event, newValue: any) => {
                            onChange(newValue);
                          }}
                          options={[
                            { id: false, name: "No"  },
                            { id: true, name: "Si"  },
                          ]}
                          getOptionLabel={(option: any) => option.name || ""}
                          isOptionEqualToValue={(option: any, value: any) =>
                              option.id === value.id
                          }
                          //@ts-ignore
                          value={value}
                          renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label={"Notificar"}
                                    variant="standard"
                                    sx={{ "& input": { pl: "0!important" } }}
                                    error={!!errors.notify}
                                    helperText={errors.notify && errors.notify.message}
                                />
                            );
                          }}
                      />
                  )}
              />
            </Grid> */}
            {/* 
            <Grid item xs={12}>
              <Controller
                control={control}
                name="adminLogin"
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChange}
                        checked={value}
                        {...field}
                      />
                    }
                    label="Login Portal Admin"
                  />
                )}
              />
              {errors?.adminLogin && (
                <FormHelperText>{errors.adminLogin.message}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="clientLogin"
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChange}
                        checked={value}
                        {...field}
                      />
                    }
                    label="Login Portal Ejecutivo"
                  />
                )}
              />
              {errors?.clientLogin && (
                <FormHelperText>{errors.clientLogin.message}</FormHelperText>
              )}
            </Grid>
            */}
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {"Aceptar"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {"Cancelar"}
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateRoleModal;
