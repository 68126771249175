import React, { useEffect, useState } from 'react';
import {
  Grid,
  Stack,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TableFooter,
  FormControl,
  InputLabel,
  Tab,
  Tabs,
  Box,
  Typography,
  Autocomplete,
  TextField
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from 'dayjs';
import FancyPaper from "../components/FancyPaper";
import { getRequestDrivers, getRequestDriversExcel } from '../services/report/ReportServices';
import { useApp } from '../hooks/useApp';
import { getRequestALLDrivers } from '../services/user/User';
import FancyTablePagination from '../components/utils/FancyTablePagination';

interface ReportData {
  id: number;
  cliente: string;
  pedidos: number;
  reporte: number;
  fecha: string;
  volMed: number;
}

const SettingReportDrivers = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [reportData, setReportData] = useState<any>([]);
  const [companyData, setCompanyData] = useState<any>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const companies = ['Carlos Arguedas', 'Andry backend', 'Juan Carlos']; // Ejemplo de empresas

  console.log(selectedCompany)

  useEffect(() => {
    const dataInit = async () => {
      //await handleFetchData(0, "");
      handleDataDrivers(0, "");
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  const handleApplyFilter = async () => {
    await handleFetchData(0, preFilter);

  };

  const handleFetchData = async (
    currentPage: number,
    filter: string,
    applyRole?: any
  ) => {
    setReportData([]);
    const start = startDate ? startDate.format('YYYY-MM-DD') : '';
    const end = endDate ? endDate.format('YYYY-MM-DD') : '';
    const companyId = selectedCompany ? selectedCompany.id : null;
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(currentPage);
      //getRequestOrders
      // try {
      let data = await getRequestDrivers(currentPage, start, end, companyId);
      if (data) {
        setReportData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleDataDrivers = async (
    currentPage: number,
    filter: string,
    applyRole?: any
  ) => {
    setLoading && setLoading(true);
    try {
      let data = await getRequestALLDrivers(currentPage, filter);
      if (data) {
        setCompanyData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const exportToExcel = async () => {
    //getRequestOrdersExcel
    const start = startDate ? startDate.format('YYYY-MM-DD') : '';
    const end = endDate ? endDate.format('YYYY-MM-DD') : '';
    const companyId = selectedCompany ? selectedCompany.id : null;
    setLoading && setLoading(true);
    try {
      let data = await getRequestDriversExcel(0, start, end, companyId);
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };


  const labelStyles = {
    '&:not(.MuiInputLabel-shrink)': {
      top: '-10px', // Ajusta este valor para la posición cuando no está seleccionado
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }

    /* if (customPage !== page) {
      await handleFetchData(customPage, filter);
    } */
    //await handleFetchData(0, filter);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <FancyPaper pagetitle="Reportes / Mensajeros">
      <Grid container spacing={8} sx={{ pb: 8 }}>
        <Grid item md={12} sm={12} xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={exportToExcel}
              sx={{
                borderRadius: "0.2rem 0.2rem 0.2rem 0.2rem!important",
                padding: "6px 16px!important",
              }}
            >
              Descargar en Excel
            </Button>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Fecha de inicio"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  format="YYYY-MM-DD"
                />
                <DatePicker
                  label="Fecha de fin"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  format="YYYY-MM-DD"
                />
              </LocalizationProvider>
              <Autocomplete
                 options={companyData?.content?.map((company:any) => ({
                  id: company.id,
                  label: company.firstName +" "+ company.lastName,
                }))}
                value={selectedCompany}
                onChange={(event, newValue) => setSelectedCompany(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Mensajero"
                    variant="outlined"
                    InputLabelProps={{
                      sx: labelStyles,
                    }}
                  />
                )}
                sx={{ minWidth: 200 }}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: "0 0.2rem 0.2rem 0!important",
                  padding: "1.5px 16px!important",
                }}
              >
                Buscar
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ width: '100%' }}>
        {/* <Tabs value={tabIndex} onChange={handleTabChange} aria-label="report tabs" sx={tabStyles}>
          <Tab label="Ocupación y ausentismo de Mensajeros" />
          <Tab label="Casos y calidad por Mensajero" />
        </Tabs>
        <Typography variant="h6">Ocupación de Mensajeros</Typography> */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID Pedido</TableCell>
                <TableCell align="center">Mensajero</TableCell>
                <TableCell align="center">Fecha</TableCell>
                <TableCell align="center">Estado</TableCell>
                <TableCell align="center">Calificación</TableCell>
                <TableCell align="center">Comentarios</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData && reportData?.content?.map((row: any) => (
                  <TableRow key={row.id}>
                    {/* <TableCell align="center">{row.id}</TableCell> */}
                    <TableCell align="center">{row?.deliveryCode}</TableCell>
                    <TableCell align="center">{row?.name}</TableCell>
                    <TableCell align="center">{row?.createDate.substring(0, 10) +" "+ row?.createDate.substring(11, 19) }</TableCell>
                    <TableCell align="center">{row?.orderStatus ? row?.orderStatus : "No disponible"}</TableCell>
                    <TableCell align="center">{row?.starts ? row?.starts +" "+ "Estrellas" : "Sin calificación"}</TableCell>
                    <TableCell align="center">{row?.comments ? row?.comments : "Sin comentarios"}</TableCell>
                  </TableRow>
                ))}
                {!reportData ||
                !reportData.content ||
                (reportData.content?.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={12} align='center'>
                    {"No hay registros"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
            count={
              reportData?.content?.length > 0 ? reportData?.content?.length : 0
            }
            rowsPerPage={reportData.size}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            totalElements={reportData.totalElements}
            totalPages={reportData.totalPages}
          />
      </Box>
    </FancyPaper>
  );
};
export default SettingReportDrivers;
